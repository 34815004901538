<template>
  <div>
    <v-row class="mb-5">
      <v-col cols="auto" align-self="center">
        <h2 class="text-h2">{{ $t("Users") }}</h2>
      </v-col>
      <v-col cols="auto">
        <v-btn depressed color="primary" @click="showNewUserForm">
          <v-icon size="18" class="mr-2">$plus</v-icon>
          {{ $t("Add") }}
        </v-btn>
      </v-col>
      <v-spacer />
      <v-col cols="auto" class="d-flex flex-grow-1 justify-end">
        <v-text-field
          v-model="filterUsers"
          dense
          outlined
          hide-details="auto"
          clearable
          clear-icon="$closeCircle"
          :label="$t('Name, login, description')"
          style="max-width: 320px"
        >
          <template #prepend-inner>
            <v-icon class="pt-1 mr-1 grey--text" size="20">$search</v-icon>
          </template>
        </v-text-field>
        <v-btn
          depressed
          class="ml-4"
          color="primary"
          :outlined="filterIsEmpty"
          width="150"
          @click="showFilters = !showFilters"
        >
          <template v-if="!showFilters">
            <v-icon class="mr-2" size="20">$filter</v-icon>
            {{ $t("Filters") }}
          </template>
          <template v-else>
            <v-icon class="mr-2" size="20">$up</v-icon>
            {{ $t("Collapse") }}
          </template>
        </v-btn>
      </v-col>
    </v-row>

    <filter-component
      v-model="filterModel"
      :show="showFilters"
      :data="filterModel"
      @getDataFilters="applyFilter"
      @filterIsEmpty="filterIsEmpty = $event"
    />

    <v-data-table
      :headers="translatedTableHeaders"
      :items="tableItems"
      sort-by="ID"
      :sort-desc="true"
      :items-per-page="-1"
      :loading="tableLoading"
      :header-props="{ 'sort-icon': '$swap' }"
      :loading-text="`${$t('Loading')}...`"
      class="table-striped"
      :search="filterUsers"
      :no-data-text="$t('Data not found')"
      :no-results-text="$t('Data not found')"
      :footer-props="{
        'items-per-page-text': $t('Lines per page'),
        'items-per-page-options': [15, 25, 50, 100],
      }"
    >
      <template #[`item.ACTIONS`]="{ item }">
        <div v-if="userId !== item.ID" class="d-inline-flex">
          <v-tooltip v-if="+item.ROLE >= 2" bottom color="primary">
            <template #activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                class="mr-4"
                color="primary"
                @click="showUserObjects(item)"
                v-on="on"
              >
                $home
              </v-icon>
            </template>
            {{ $t("Objects to visit") }}
          </v-tooltip>
          <v-icon class="mr-4" color="red" @click="showRemoveUserConfirm(item)">
            $delete
          </v-icon>
          <v-icon
            class="mr-4"
            :color="item.STATUS != 2 ? 'red' : 'green'"
            @click="blockToggleUser(item)"
          >
            $lock
          </v-icon>
          <v-icon color="primary" @click="showEditUserForm(item)">
            $edit
          </v-icon>
        </div>
      </template>
      <template #[`item.STATUS`]="{ item }">
        <span :class="item.STATUS !== 1 ? 'red--text' : 'green--text'">
          {{ $t(USER_STATUSES.find((elem) => elem.value == item.STATUS)?.text) }}
        </span>
      </template>
      <template #[`item.ROLE`]="{ item }">
        {{ $t(USER_ROLES.find((elem) => elem.value == item.ROLE)?.text) }}
      </template>
    </v-data-table>

    <v-dialog v-model="userFormDialog" max-width="580">
      <v-card>
        <v-card-actions class="justify-end">
          <v-icon size="16" class="mr-n3" @click="userFormDialog = false">
            $close
          </v-icon>
        </v-card-actions>
        <v-card-title class="justify-center mb-6">
          <h3 class="text-h2">
            {{ isNewUserForm ? $t("Adding a user") : $t("Editing a user") }}
          </h3>
        </v-card-title>
        <v-card-text style="max-width: 410px; margin: 0 auto" class="text-center pb-16">
          <v-form ref="userForm" v-model="userFormValid" @keyup.native.enter="userFormSubmit">
            <v-select
              v-model="userForm.ROLE"
              :items="translateUserRoles"
              :rules="userFormRules.ROLE"
              :menu-props="{ offsetY: true }"
              hide-details="auto"
              class="mb-5"
              append-icon="$down"
              outlined
            >
              <template #label>
                {{ $t("Role") }}<span class="red--text">*</span>
              </template>
            </v-select>
            <v-text-field
              v-model="userForm.NAME"
              :rules="userFormRules.NAME"
              hide-details="auto"
              class="mb-5"
              outlined
            >
              <template #label>
                {{ $t("Name") }}<span class="red--text">*</span>
              </template>
            </v-text-field>
            <v-select
              v-model="userForm.STATUS"
              :items="translateUserStatuses"
              :rules="userFormRules.STATUS"
              :menu-props="{ offsetY: true }"
              class="mb-5"
              append-icon="$down"
              hide-details="auto"
              outlined
            >
              <template #label>
                {{ $t("Status") }}<span class="red--text">*</span>
              </template>
            </v-select>
            <v-text-field
              v-model="userForm.LOGIN"
              :rules="userFormRules.LOGIN"
              hide-details="auto"
              class="mb-5"
              outlined
            >
              <template #append>
                <v-icon v-if="userForm.LOGIN" @click="copyField(userForm.LOGIN, $t('Username'))">
                  $copy
                </v-icon>
              </template>
              <template #label>
                {{ $t("Login") }}<span class="red--text">*</span>
              </template>
            </v-text-field>
            <v-text-field
              v-model="userForm.PASSWORD"
              :rules="userFormRules.PASSWORD"
              hide-details="auto"
              class="mb-5"
              outlined
            >
              <template #append>
                <v-btn
                  v-if="!userForm.PASSWORD"
                  height="20px"
                  class="pa-1 mr-2"
                  color="primary"
                  text
                  @click="userForm.PASSWORD = generatePass()"
                >
                  {{ $t("Generate") }}
                </v-btn>
                <v-icon
                  v-if="userForm.PASSWORD"
                  @click="copyField(userForm.PASSWORD, $t('Password'))"
                >
                  $copy
                </v-icon>
              </template>
              <template #label>
                {{ $t("Password") }}<span class="red--text">*</span>
              </template>
            </v-text-field>
            <v-textarea
              v-model="userForm.DESCRIPTION"
              :label="$t('Description')"
              class="mb-5"
              rows="4"
              row-height="30"
              hide-details="auto"
              outlined
            />
            <v-btn
              depressed
              block
              color="primary"
              :disabled="!userFormValid"
              @click="userFormSubmit"
            >
              {{ isNewUserForm ? $t("Add") : $t("Save") }}
            </v-btn>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>

    <confirm-remove-dialog
      v-if="userRemoveConfirm"
      :loading="userRemoveLoading"
      @closeDialog="userRemoveConfirm = false"
      @confirm="removeUser"
    >
      <template #title>
        {{ $t("Delete user") }} «{{ userForm.NAME }}»?
      </template>
      <template #default>
        {{ $t("After deletion, it will be impossible to restore access zone data") }}.
      </template>
    </confirm-remove-dialog>

    <checkbox-list-dialog
      v-if="showUserObjectsDialog"
      :objects="objects"
      :title="$t('Objects to visit')"
      :subtitle="userForm.NAME"
      :headers="[{ text: 'NAME', value: 'OBJECT_NAME' }]"
      item-key="OBJECT_ID"
      @closeDialog="showUserObjectsDialog = false"
      @itemSelected="objectToggle"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import api from '@/api';
import { COMMON_SET_LOADING } from '@/store/types/mutation-types';
import { SNACK_ADD_MESSAGE } from '@/store/types/action-types';
import { USER_ROLES, USER_STATUSES } from '@/constants';
import CheckboxListDialog from '@/components/blocks/CheckboxListDialog.vue';
import ConfirmRemoveDialog from '@/components/blocks/ConfirmRemoveDialog.vue';
import FilterComponent from '@/components/blocks/FilterComponent.vue';
import { filterModel, tableHeaders } from './data.user';
import { copyToClipboard } from '@/helpers/helpers';

export default {
  name: 'UsersPage',
  components: { ConfirmRemoveDialog, CheckboxListDialog, FilterComponent },
  data() {
    return {
      filterModel,
      tableHeaders,
      USER_STATUSES,
      USER_ROLES,

      filterUsers: '',
      filterIsEmpty: true,
      isNewUserForm: true,
      objects: [],
      showFilters: false,
      showUserObjectsDialog: false,
      tableItems: [],
      tableLoading: false,
      userRemoveConfirm: false,
      userRemoveLoading: false,
      userFormDialog: false,
      userFormValid: false,
      userForm: this.createUserFormModel(),
      userFormRules: {
        NAME: [(v) => !!v || `${this.$t('You must fill in «Name»')}.`],
        LOGIN: [(v) => !!v || `${this.$t('You need to fill in «Login»')}.`],
        PASSWORD: [(v) => !!v || `${this.$t('You need to fill in «Password»')}.`],
        ROLE: [(v) => !!v || v === 0 || `${this.$t('You need to fill in «Role»')}.`],
        STATUS: [(v) => !!v || v === 0 || `${this.$t('You need to fill in «Status»')}.`],
      },
    };
  },
  computed: {
    ...mapState({
      userId: (state) => state.user.model.ID,
    }),
    translatedTableHeaders() {
      return this.tableHeaders.map((elem) => ({ ...elem, text: this.$t(elem.text) }));
    },
    translateUserRoles() {
      return USER_ROLES.map((elem) => ({ ...elem, text: this.$t(elem.text) }));
    },
    translateUserStatuses() {
      return USER_STATUSES.map((elem) => ({ ...elem, text: this.$t(elem.text) }));
    },
  },
  mounted() {
    this.applyFilter();
  },
  methods: {
    showNewUserForm() {
      if (this.$refs.userForm) this.$refs.userForm.resetValidation();
      this.userForm = this.createUserFormModel();
      this.isNewUserForm = true;
      this.userFormDialog = true;
    },
    showEditUserForm(user) {
      if (!user) return false;
      this.userForm = this.createUserFormModel(user);
      this.isNewUserForm = false;
      this.userFormDialog = true;
    },
    showRemoveUserConfirm(user) {
      this.userForm = this.createUserFormModel(user);
      this.userRemoveConfirm = true;
    },
    showUserObjects(user) {
      if (!user) return false;
      this.userForm = this.createUserFormModel(user);
      this.loadObjects().then(() => (this.showUserObjectsDialog = true));
    },
    createUserFormModel(user = {}) {
      return {
        ID: user.ID || null,
        ROLE: user.ROLE === 0 ? 0 : user.ROLE || '',
        NAME: user.NAME || '',
        STATUS: user.STATUS || 2,
        LOGIN: user.LOGIN || '',
        PASSWORD: user.PASSWORD || '',
        DESCRIPTION: user.DESCRIPTION || '',
        I_CONFIRM: user.STATUS === 1,
      };
    },
    userFormSubmit() {
      if (this.$refs.userForm.validate()) {
        const formData = new FormData();
        Object.keys(this.userForm).forEach((key) => {
          let value = this.userForm[key];
          value = value === null ? '' : value;
          formData.append(key.toLowerCase(), value);
        });

        this.$store.commit(COMMON_SET_LOADING);
        if (this.isNewUserForm) {
          api.users
            .create(formData)
            .then((res) => {
              this.userFormDialog = false;
              this.tableItems.push(res.data.DATA);
              this.$store.dispatch(SNACK_ADD_MESSAGE, {
                type: 'success',
                text: this.$i18n.locale === 'ru' ? res.data.MESSAGE : this.$t(res.data.MESSAGE),
              });
            })
            .catch((error) => {
              this.$store.dispatch(SNACK_ADD_MESSAGE, this.$t(error.response.data.MESSAGE));
            })
            .finally(() => this.$store.commit(COMMON_SET_LOADING, false));
        } else {
          api.users
            .update(this.userForm.ID, formData)
            .then((res) => {
              this.userFormDialog = false;
              const userIndex = this.tableItems.findIndex((item) => item.ID === this.userForm.ID);
              Object.assign(this.tableItems[userIndex], this.userForm);
              this.$store.dispatch(SNACK_ADD_MESSAGE, {
                type: 'success',
                text: this.$i18n.locale === 'ru' ? res.data.MESSAGE : this.$t(res.data.MESSAGE),
              });
            })
            .catch((error) => {
              this.$store.dispatch(SNACK_ADD_MESSAGE, this.$t(error.response.data.MESSAGE));
            })
            .finally(() => this.$store.commit(COMMON_SET_LOADING, false));
        }
      }
    },
    blockToggleUser(user) {
      if (!user || this.userId == user.ID) return false;
      this.userForm = this.createUserFormModel(user);
      this.isNewUserForm = false;
      this.userForm.STATUS = this.userForm.STATUS != 2 ? 2 : 1;
      const formData = new FormData();
      Object.keys(this.userForm).forEach((key) => {
        let value = this.userForm[key];
        value = value === null ? '' : value;
        formData.append(key.toLowerCase(), value);
      });
      this.$store.commit(COMMON_SET_LOADING);
      api.users
        .update(this.userForm.ID, formData)
        .then((res) => {
          this.$store.commit(COMMON_SET_LOADING, false);
          const userIndex = this.tableItems.findIndex((item) => item.ID === this.userForm.ID);
          Object.assign(this.tableItems[userIndex], this.userForm);
          this.$store.dispatch(SNACK_ADD_MESSAGE, {
            type: 'success',
            text: this.$i18n.locale === 'ru' ? res.data.MESSAGE : this.$t(res.data.MESSAGE),
          });
        })
        .catch((error) => {
          this.$store.commit(COMMON_SET_LOADING, false);
          this.$store.dispatch(SNACK_ADD_MESSAGE, this.$t(error.response.data.MESSAGE));
        });
    },
    removeUser() {
      this.userRemoveLoading = true;
      api.users
        .remove(this.userForm.ID)
        .then((res) => {
          if (res.data.DATA) {
            this.userRemoveConfirm = false;
            this.userRemoveLoading = false;
            const userIndex = this.tableItems.findIndex((item) => item.ID === this.userForm.ID);
            this.tableItems.splice(userIndex, 1);
            this.$store.dispatch(SNACK_ADD_MESSAGE, {
              type: 'success',
              text: this.$i18n.locale === 'ru' ? res.data.MESSAGE : this.$t(res.data.MESSAGE),
            });
          }
        })
        .catch((error) => {
          this.userRemoveConfirm = false;
          this.userRemoveLoading = false;
          this.$store.dispatch(SNACK_ADD_MESSAGE, this.$t(error.response.data.MESSAGE));
        });
    },
    objectToggle({ item, value }) {
      this.$store.commit(COMMON_SET_LOADING);
      api.users
        .changeObjects({
          id: this.userForm.ID,
          object_id: item.OBJECT_ID,
          is_bind: value,
        })
        .then(() => this.loadObjects())
        .finally(() => this.$store.commit(COMMON_SET_LOADING, false));
    },
    loadObjects() {
      return api.users.getObjects(this.userForm.ID).then((res) => (this.objects = res.data.DATA));
    },
    copyField(item, label) {
      copyToClipboard(item);
      this.$store.dispatch(SNACK_ADD_MESSAGE, {
        type: 'success',
        text: `${label} ${this.$t('copied')}`,
      });
    },
    applyFilter() {
      this.$store.commit(COMMON_SET_LOADING);
      const status = this.getValueFromFilter('STATUS');
      const role = this.getValueFromFilter('ROLE');
      api.users
        .getAll({ status, role })
        .then((res) => (this.tableItems = res.data.DATA))
        .finally(() => this.$store.commit(COMMON_SET_LOADING, false));
    },
    getValueFromFilter(field) {
      return this.filterModel.find((item) => item.name === field).data;
    },
    generatePass() {
      const CharacterSet = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!%&*$#^@';
      const lengthPass = 12;
      let password = '';
      for (let i = 0; i < lengthPass; i++) {
        password += CharacterSet.charAt(Math.floor(Math.random() * CharacterSet.length));
      }
      return password;
    },
  },
};
</script>
