<script>
import { REQUEST_IS_MULTIPASS, ALLOWED_PASS_TYPES } from '@/constants/index.js';

export default {
  name: 'MultiPassRadioBtn',
  props: {
    isMultiPassProp: {
      type: Boolean,
      default: null,
    },
    allowedPassTypeProp: {
      type: Number,
      default: ALLOWED_PASS_TYPES.singleAndMultiPass,
    },
    defaultPassTypeProp: {
      type: Number,
      default: ALLOWED_PASS_TYPES.singlePass,
    },
  },
  data() {
    return {
      value: this.isMultiPassProp,
      multiPassRules: [(v) => typeof v === 'boolean' || this.$t('You must select the value «Multipass»')],
      multiPassData: REQUEST_IS_MULTIPASS,
      defaultPassType: this.defaultPassTypeProp,
    };
  },
  watch: {
    allowedPassTypeProp: {
      handler(currentValue) {
        const isMultiPass = (currentValue === ALLOWED_PASS_TYPES.multiPass);

        if (currentValue === ALLOWED_PASS_TYPES.singleAndMultiPass) {
          this.multiPassData.forEach((item) => { item.disabled = false; });
          this.updateMultiPass(this.defaultPassType);
          return;
        }

        this.updateMultiPass(currentValue);

        this.multiPassData[0].disabled = !isMultiPass;
        this.multiPassData[1].disabled = !!isMultiPass;
      },
      immediate: true,
    },
    isMultiPassProp: {
      handler() {
        this.value = this.isMultiPassProp;
      },
    },
    defaultPassTypeProp: {
      handler() {
        this.defaultPassType = this.defaultPassTypeProp;
        this.updateMultiPass(this.defaultPassType);
      },
      immediate: true,
    },
  },
  methods: {
    updateMultiPass(currentValue) {
      const isMultiPass = (currentValue === ALLOWED_PASS_TYPES.multiPass);
      this.value = isMultiPass;
      this.$emit('update-state-fabric', isMultiPass);
    },
  },
};
</script>

<template>
  <v-radio-group
    v-model="value"
    :rules="multiPassRules"
    :disabled="!$emit('can-edit-fields')"
    hide-details="auto"
    class="mb-5 mt-1"
    row
    @change="$emit('update-state-fabric', value)"
  >
    <v-radio
      v-for="n in multiPassData"
      :key="n.text"
      :value="n.value"
      :label="n.text"
      :disabled="n.disabled"
    >
      <template #label>
        <span
          :class="{
            'text--primary': !n.disabled,
            'text--disabled': n.disabled,
          }"
        >
          {{ $t(n.text) }}
        </span>
      </template>
    </v-radio>
  </v-radio-group>
</template>
