<template>
  <div>
    <v-tabs v-model="tabsModel" class="srp-tabs d-flex flex-column" @change="applyFilter">
      <v-row class="mb-5">
        <v-col cols="auto" align-self="center">
          <h2 class="text-h2 text--primary">{{ $t("Objects to visit") }}</h2>
        </v-col>
        <v-tab :href="`#tab-1`">{{ $t("Local") }}</v-tab>
        <v-tab :href="`#tab-2`">{{ $t("External") }}</v-tab>
        <v-col cols="auto">
          <v-btn v-if="tabsModel === 'tab-1'" depressed color="primary" @click="showNewObjectForm">
            <v-icon size="18" class="mr-2">$plus</v-icon>
            {{ $t("Add") }}
          </v-btn>
        </v-col>
        <v-spacer />
        <v-col cols="auto" class="d-flex flex-grow-1 justify-end">
          <v-text-field
            v-model="filterObjects"
            dense
            outlined
            hide-details="auto"
            clearable
            clear-icon="$closeCircle"
            :label="$t('Name, description')"
            style="max-width: 320px"
          >
            <template #prepend-inner>
              <v-icon class="pt-1 mr-1 grey--text" size="20">$search</v-icon>
            </template>
          </v-text-field>
          <v-btn
            depressed
            class="ml-4"
            color="primary"
            :outlined="filterIsEmpty"
            width="150"
            @click="showFilters = !showFilters"
          >
            <template v-if="!showFilters">
              <v-icon class="mr-2" size="20">$filter</v-icon>
              {{ $t("Filters") }}
            </template>
            <template v-else>
              <v-icon class="mr-2" size="20">$up</v-icon>
              {{ $t("Collapse") }}
            </template>
          </v-btn>
        </v-col>
      </v-row>
      <v-tab-item class="pt-8" :value="`tab-1`">
        <filter-component
          v-model="filterModelForTab"
          :show="showFilters"
          :data="filterModel"
          @getDataFilters="applyFilter"
          @filterIsEmpty="filterIsEmpty = $event"
        />
        <table-objects
          :objects="localObjects"
          :table-headers="tableHeadersLocal"
          :search="filterObjects"
          @showZones="showZones"
          @delete="showRemoveObjectConfirm"
          @block="blockToggleObject"
          @edit="showEditObjectForm"
        />
      </v-tab-item>
      <v-tab-item class="pt-8" :value="`tab-2`">
        <filter-component
          v-model="filterModelForTab"
          :show="showFilters"
          :data="filterModel"
          @getDataFilters="applyFilter"
          @filterIsEmpty="filterIsEmpty = $event"
        />
        <table-objects
          :objects="externalObjects"
          :table-headers="tableHeadersExternal"
          :search="filterObjects"
          type="external"
          @showZones="showZones"
        />
      </v-tab-item>
    </v-tabs>

    <v-dialog v-model="objectFormDialog" max-width="580">
      <v-card>
        <v-card-actions class="justify-end">
          <v-icon size="16" class="mr-n3" @click="objectFormDialog = false">$close</v-icon>
        </v-card-actions>
        <v-card-title class="justify-center mb-6">
          <h3 class="text-h2 text-center" style="word-break: break-word">
            {{
              isNewObjectForm
                ? $t("Adding local object to visit")
                : $t("Editing local object to visit")
            }}
          </h3>
        </v-card-title>
        <v-card-text style="max-width: 410px; margin: 0 auto" class="text-center pb-16">
          <v-form
            ref="objectForm"
            v-model="objectFormValid"
            class="d-flex flex-column"
            @keyup.native.enter="objectFormSubmit"
          >
            <v-text-field
              v-model="objectForm.NAME"
              :rules="objectFormRules.NAME"
              hide-details="auto"
              class="mb-5"
              outlined
            >
              <template #label>
                {{ $t("Name") }}<span class="red--text">*</span>
              </template>
            </v-text-field>
            <v-select
              v-model="objectForm.STATUS"
              :items="translatedObjectStatuses"
              :rules="objectFormRules.STATUS"
              :menu-props="{ offsetY: true }"
              class="mb-5"
              append-icon="$down"
              hide-details="auto"
              outlined
            >
              <template #label>
                {{ $t("Status") }}<span class="red--text">*</span>
              </template>
            </v-select>
            <request-statuses
              :default-pass-status="objectForm.DEFAULT_PASS_STATUS"
              @update-state-fabric="(value) =>
                updateStateFabric('DEFAULT_PASS_STATUS', value, objectForm)"
            />
            <pass-types
              class="sid-settings-field__name"
              :prop-default-allowed-pass-type-value="objectForm.ALLOWED_PASS_TYPE"
              :prop-default-pass-type-value="objectForm.DEFAULT_PASS_TYPE"
              @update-allowed-pass-type="
                (value) => updateStateFabric('ALLOWED_PASS_TYPE', value, objectForm)"
              @update-default-pass-type="
                (value) => updateStateFabric('DEFAULT_PASS_TYPE', value, objectForm)"
            />
            <v-textarea
              v-model="objectForm.FOOTER"
              class="mb-5"
              rows="4"
              row-height="30"
              hide-details="auto"
              outlined
            >
              <template #label>
                {{ $t("Header") }}
              </template>
            </v-textarea>
            <div class="d-flex justify-start align-center">
              <p class="text--primary mr-1 mb-0 font-weight-medium">
                {{ $t("Logo") }}
              </p>
              <v-tooltip right color="white" content-class="tooltip-shadow">
                <template #activator="{ on, attrs }">
                  <v-icon v-bind="attrs" color="primary" v-on="on">$question</v-icon>
                </template>
                <v-img :src="require('../../assets/images/logo_example.png')" />
              </v-tooltip>
            </div>
            <v-badge v-if="objectForm.LOGO" color="" class="mr-auto">
              <template #badge>
                <v-btn text icon small elevation="2" @click="removeUserLogo">
                  <v-icon color="red">$delete</v-icon>
                </v-btn>
              </template>
              <v-img
                :src="convertUserLogo(objectForm.LOGO)"
                max-height="50"
                max-width="50"
                class="mb-4"
                contain
              />
            </v-badge>

            <div class="drop-file-block mb-7" @click="pickUserLogo">
              <div class="drop-file-block__inner">
                <v-icon size="14">$plus</v-icon>
                <div class="subtitle-2">{{ $t("Click to upload the .png file") }}</div>
                <div class="subtitle-2 grey--text">{{ $t("Recommended file resolution 150x150") }}</div>
              </div>
            </div>

            <input
              ref="newUserLogo"
              type="file"
              style="display: none"
              accept="image/png"
              @change="handleLogoFileUpload()"
            >
            <div
              v-if="objectFormRules.LOGO.length > 0"
              class="v-messages__message error--text mb-5"
            >
              {{ objectFormRules.LOGO.join('<br /><br />') }}
            </div>
            <div class="d-flex justify-start align-center">
              <p class="text--primary mr-1 mb-0 font-weight-medium">
                {{ $t("Logo for Wallet") }}
              </p>
              <v-tooltip right color="white" content-class="tooltip-shadow">
                <template #activator="{ on, attrs }">
                  <v-icon v-bind="attrs" color="primary" v-on="on">$question</v-icon>
                </template>
                <v-img :src="require('../../assets/images/logo_wallet_example.png')" />
              </v-tooltip>
            </div>
            <v-badge v-if="objectForm.LOGO_WALLET" color="" class="mr-auto">
              <template #badge>
                <v-btn text icon small elevation="2" @click="removeUserWallet">
                  <v-icon color="red">$delete</v-icon>
                </v-btn>
              </template>
              <v-img
                :src="convertUserLogo(objectForm.LOGO_WALLET)"
                max-height="50"
                max-width="50"
                class="mb-4"
                contain
              />
            </v-badge>

            <div class="drop-file-block mb-7" @click="pickUserWallet">
              <div class="drop-file-block__inner">
                <v-icon size="14">$plus</v-icon>
                <div class="subtitle-2">{{ $t("Click to upload the .png file") }}</div>
                <div class="subtitle-2 grey--text">{{ $t("Recommended file resolution 150x150") }}</div>
              </div>
            </div>

            <input
              ref="newUserWallet"
              type="file"
              style="display: none"
              accept="image/png"
              @change="handleWalletFileUpload()"
            >
            <div
              v-if="objectFormRules.LOGO_WALLET.length > 0"
              class="v-messages__message error--text mb-5"
            >
              {{ objectFormRules.LOGO_WALLET.join('<br /><br />') }}
            </div>

            <v-textarea
              v-model="objectForm.DESCRIPTION"
              :label="$t('Description')"
              class="mb-5"
              rows="4"
              row-height="30"
              hide-details="auto"
              outlined
            />
            <v-btn
              depressed
              block
              color="primary"
              :disabled="!objectFormValid"
              @click="objectFormSubmit"
            >
              {{ isNewObjectForm ? $t("Add") : $t("Save") }}
            </v-btn>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
    <confirm-remove-dialog
      v-if="objectRemoveConfirm"
      :loading="objectRemoveLoading"
      @closeDialog="objectRemoveConfirm = false"
      @confirm="removeObject"
    >
      <template #title>
        {{ $t("Delete object to visit") }} «{{ objectForm.NAME }}»?
      </template>
      <template #default>
        {{ $t("After deletion, it will be impossible to restore the data of the visited object") }}.
      </template>
    </confirm-remove-dialog>

    <checkbox-list-dialog
      v-if="showZonesDialog"
      :objects="zones"
      :title="$t('Access zones')"
      :subtitle="objectForm.NAME"
      :headers="[{ text: 'NAME', value: 'ZONE_NAME' }]"
      :disabled-checkbox="objectForm.SYSTEM_ID != 0"
      item-key="ZONE_ID"
      @closeDialog="showZonesDialog = false"
      @itemSelected="zoneToggle"
    />
  </div>
</template>

<script>
import api from '@/api';
import { COMMON_SET_LOADING } from '@/store/types/mutation-types';
import { SNACK_ADD_MESSAGE } from '@/store/types/action-types';
import TableObjects from './TableObjects.vue';
import { dataURLtoFile } from '@/helpers/helpers';
import { OBJECT_STATUSES } from '@/constants';
import { tableHeadersExternal, tableHeadersLocal, filterModel } from './data.objects';
import ConfirmRemoveDialog from '@/components/blocks/ConfirmRemoveDialog.vue';
import CheckboxListDialog from '@/components/blocks/CheckboxListDialog.vue';
import FilterComponent from '@/components/blocks/FilterComponent.vue';
import RequestStatuses from '@/components/blocks/RequestStatuses.vue';
import PassTypes from '@/components/blocks/PassTypes.vue';
import updateStateMixin from '@/mixins/updateStateMixin.js';
import { ALLOWED_PASS_TYPES, REQUEST_STATUSES } from '@/constants/index.js';

export default {
  name: 'ObjectsPage',
  components: {
    TableObjects,
    ConfirmRemoveDialog,
    CheckboxListDialog,
    FilterComponent,
    RequestStatuses,
    PassTypes,
  },
  mixins: [updateStateMixin],
  data() {
    return {
      filterModel,
      OBJECT_STATUSES,
      tableHeadersExternal,
      tableHeadersLocal,
      allObjects: [],
      filterObjects: '',
      filterBySystem: [],
      filterIsEmpty: true,
      isNewObjectForm: false,
      objectForm: this.createObjectFormModel(),
      objectFormDialog: false,
      objectFormValid: false,
      objectRemoveConfirm: false,
      objectRemoveLoading: false,
      newUserWallet: null,
      newUserLogo: null,
      showFilters: false,
      showZonesDialog: false,
      tabsModel: 'tab-1',
      zones: [],
      objectFormRules: {
        NAME: [(v) => !!v || `${this.$t('You must fill in «Name»')}.`],
        STATUS: [(v) => !!v || `${this.$t('You need to fill in «Status»')}.`],
        LOGO_WALLET: [],
        LOGO: [],
      },
    };
  },
  computed: {
    localObjects() {
      return this.allObjects.filter((object) => object.SYSTEM_ID === 0);
    },
    externalObjects() {
      return this.allObjects.filter((object) => object.SYSTEM_ID !== 0);
    },
    filterModelForTab() {
      return this.tabsModel === 'tab-1' ? [this.filterModel[2]] : this.filterModel;
    },
    translatedObjectStatuses() {
      return this.OBJECT_STATUSES.map((elem) => ({ ...elem, text: this.$t(elem.text) }));
    },
  },
  mounted() {
    if (this.$route.query.external_system) {
      this.tabsModel = 'tab-2';
      this.filterModel[this.getIndexFromFilter('EXTERNAL_SYSTEM')].data = [
        Number(this.$route.query.external_system),
      ];
    }
    this.applyFilter();
    api.externalSystems.getAll().then((res) => {
      res.data.DATA.forEach((system) => this.filterModel[this.getIndexFromFilter('EXTERNAL_SYSTEM')]?.items.push({
        text: system.NAME,
        value: system.ID,
      }));
    });
  },

  methods: {
    applyFilter() {
      this.$store.commit(COMMON_SET_LOADING);
      const system = this.getValueFromFilter('EXTERNAL_SYSTEM');
      const sid = this.getValueFromFilter('SID');
      const status = this.getValueFromFilter('STATUS');
      api.objects
        .getAll({ system, sid, status })
        .then((res) => {
          this.allObjects = res.data.DATA;
        })
        .finally(() => this.$store.commit(COMMON_SET_LOADING, false));
    },
    getIndexFromFilter(field) {
      return this.filterModel.findIndex((item) => item.name === field);
    },
    getValueFromFilter(field) {
      return this.filterModelForTab.find((item) => item.name === field)?.data;
    },
    showNewObjectForm() {
      if (this.$refs.objectForm) this.$refs.objectForm.resetValidation();
      this.isNewObjectForm = true;
      this.objectForm = this.createObjectFormModel();
      this.objectFormDialog = true;
    },
    showRemoveObjectConfirm(object) {
      this.objectForm = this.createObjectFormModel(object);
      this.objectRemoveConfirm = true;
    },
    showEditObjectForm(object) {
      if (!object) return false;
      this.isNewObjectForm = false;
      this.objectForm = this.createObjectFormModel(object);
      this.objectFormDialog = true;
    },
    showZones(object) {
      if (!object) return false;
      this.objectForm = this.createObjectFormModel(object);
      this.loadZones().then(() => (this.showZonesDialog = true));
    },
    getFinalValue(entity, state) {
      const [systemStatus] = REQUEST_STATUSES.filter((v) => v.value === null);

      const mapToDefaultConfig = {
        DEFAULT_PASS_STATUS: systemStatus.value,
        ALLOWED_PASS_TYPE: ALLOWED_PASS_TYPES.systemValue,
        DEFAULT_PASS_TYPE: ALLOWED_PASS_TYPES.systemValue,
      };

      return this.isNewObjectForm ? mapToDefaultConfig[entity] : (null || state[entity]);
    },
    createObjectFormModel(object = {}) {
      return {
        ID: object.ID || null,
        NAME: object.NAME || '',
        STATUS: object.STATUS || 2,
        FOOTER: object.FOOTER || '',
        LOGO: object.LOGO || '',
        LOGO_WALLET: object.LOGO_WALLET || '',
        DESCRIPTION: object.DESCRIPTION || '',
        SYSTEM_ID: object.SYSTEM_ID || 0,
        DEFAULT_PASS_STATUS: this.getFinalValue('DEFAULT_PASS_STATUS', object),
        ALLOWED_PASS_TYPE: this.getFinalValue('ALLOWED_PASS_TYPE', object),
        DEFAULT_PASS_TYPE: this.getFinalValue('DEFAULT_PASS_TYPE', object),
      };
    },
    objectFormSubmit() {
      if (this.$refs.objectForm.validate()) {
        const formData = new FormData();
        Object.keys(this.objectForm).forEach((key) => {
          let value = this.objectForm[key];
          // если LOGO и LOGO_WALLET не File, то перед отправкой преобразуем их в File
          if ((key === 'LOGO' || key === 'LOGO_WALLET') && value && value.constructor !== File) {
            value = dataURLtoFile(this.convertUserLogo(value), `user_${key}.png`);
          }
          value = value === null ? '' : value;
          value = typeof value === 'boolean' ? Number(value) : value;
          formData.append(key.toLowerCase(), value);
        });

        this.$store.commit(COMMON_SET_LOADING);
        if (this.isNewObjectForm) {
          api.objects
            .create(formData)
            .then((res) => {
              this.objectFormDialog = false;
              this.allObjects.push(res.data.DATA);
              this.$store.dispatch(SNACK_ADD_MESSAGE, {
                type: 'success',
                text: this.$i18n.locale === 'ru' ? res.data.MESSAGE : this.$t(res.data.MESSAGE),
              });
            })
            .catch((error) => {
              this.$store.dispatch(SNACK_ADD_MESSAGE, this.$t(error.response.data.MESSAGE));
            })
            .finally(() => this.$store.commit(COMMON_SET_LOADING, false));
        } else {
          api.objects
            .update(this.objectForm.ID, formData)
            .then((res) => {
              this.objectFormDialog = false;
              const objectIndex = this.allObjects.findIndex(
                (item) => item.ID === this.objectForm.ID,
              );
              this.allObjects.splice(objectIndex, 1, res.data.DATA);
              this.$store.dispatch(SNACK_ADD_MESSAGE, {
                type: 'success',
                text: this.$i18n.locale === 'ru' ? res.data.MESSAGE : this.$t(res.data.MESSAGE),
              });
            })
            .catch((error) => {
              this.$store.dispatch(SNACK_ADD_MESSAGE, this.$t(error.response.data.MESSAGE));
            })
            .finally(() => this.$store.commit(COMMON_SET_LOADING, false));
        }
      }
    },
    blockToggleObject(object) {
      this.objectForm = this.createObjectFormModel(object);
      this.isNewObjectForm = false;
      this.objectForm.STATUS = this.objectForm.STATUS != 2 ? 2 : 1;
      const formData = new FormData();
      Object.keys(this.objectForm).forEach((key) => {
        let value = this.objectForm[key];
        if ((key === 'LOGO' || key === 'LOGO_WALLET') && value && value.constructor !== File) {
          value = dataURLtoFile(this.convertUserLogo(value), `user_${key}.png`);
        }
        value = value === null ? '' : value;
        value = typeof value === 'boolean' ? Number(value) : value;
        formData.append(key.toLowerCase(), value);
      });
      this.$store.commit(COMMON_SET_LOADING);
      api.objects
        .update(this.objectForm.ID, formData)
        .then((res) => {
          this.$store.commit(COMMON_SET_LOADING, false);
          const objectIndex = this.allObjects.findIndex((item) => item.ID === this.objectForm.ID);
          this.allObjects.splice(objectIndex, 1, res.data.DATA);
          this.$store.dispatch(SNACK_ADD_MESSAGE, {
            type: 'success',
            text: this.$i18n.locale === 'ru' ? res.data.MESSAGE : this.$t(res.data.MESSAGE),
          });
        })
        .catch((error) => {
          this.$store.commit(COMMON_SET_LOADING, false);
          this.$store.dispatch(SNACK_ADD_MESSAGE, this.$t(error.response.data.MESSAGE));
        });
    },
    removeObject() {
      this.objectRemoveLoading = true;
      api.objects
        .remove(this.objectForm.ID)
        .then((res) => {
          if (res.data.DATA) {
            this.objectRemoveConfirm = false;
            this.objectRemoveLoading = false;
            const objectIndex = this.allObjects.findIndex((item) => item.ID === this.objectForm.ID);
            this.allObjects.splice(objectIndex, 1);
            this.$store.dispatch(SNACK_ADD_MESSAGE, {
              type: 'success',
              text: this.$i18n.locale === 'ru' ? res.data.MESSAGE : this.$t(res.data.MESSAGE),
            });
          }
        })
        .catch((error) => {
          this.objectRemoveConfirm = false;
          this.objectRemoveLoading = false;
          this.$store.dispatch(SNACK_ADD_MESSAGE, this.$t(error.response.data.MESSAGE));
        });
    },

    loadZones() {
      return api.objects.getZones(this.objectForm.ID).then((res) => (this.zones = res.data.DATA));
    },

    convertUserLogo(logo) {
      if (!logo) return '';
      if (logo.includes('data:image/png;base64,')) return logo;
      return `data:image/png;base64, ${logo}`;
    },

    pickUserWallet() {
      this.$refs.newUserWallet.click();
    },
    pickUserLogo() {
      this.$refs.newUserLogo.click();
    },

    removeUserWallet() {
      this.newUserWallet = null;
      this.objectForm.LOGO_WALLET = '';
    },
    removeUserLogo() {
      this.newUserLogo = null;
      this.objectForm.LOGO = '';
    },
    handleWalletFileUpload() {
      this.newUserWallet = this.$refs.newUserWallet.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(this.newUserWallet);
      reader.onload = () => (this.objectForm.LOGO_WALLET = reader.result);
      reader.onerror = () => this.$store.dispatch(SNACK_ADD_MESSAGE, `${this.$t('Loading error')}!`);
    },
    handleLogoFileUpload() {
      this.newUserLogo = this.$refs.newUserLogo.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(this.newUserLogo);
      reader.onload = () => (this.objectForm.LOGO = reader.result);
      reader.onerror = () => this.$store.dispatch(SNACK_ADD_MESSAGE, `${this.$t('Loading error')}!`);
    },
    zoneToggle({ item, value }) {
      this.$store.commit(COMMON_SET_LOADING);
      api.objects
        .bindZones({
          zone_id: item.ZONE_ID,
          object_id: this.objectForm.ID,
          is_bind: value,
        })
        .then(() => this.loadZones())
        .finally(() => this.$store.commit(COMMON_SET_LOADING, false));
    },
  },
};
</script>
