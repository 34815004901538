import dateHelpers from '@/helpers/dateHelpers';

import {
  DEVICE_TYPE,
  // DEVICE_IS_ENTRY,
  FLAG_INSIDE,
  FLAG_IS_PASSED,
} from '@/constants';

export const tableHeaders = [
  {
    text: 'Full name of the visitor',
    value: 'REQUEST_V_NAME',
    cellClass: 'font-weight-medium',
    width: '210px',
  },
  {
    text: 'Visited object ID',
    value: 'OBJECT_ID',
    filterable: false,
    width: '200px',
  },
  {
    text: 'Object of visit',
    value: 'OBJECT_NAME',
    filterable: false,
    width: '200px',
  },
  {
    text: 'Device ID',
    value: 'DEVICE_ID',
    filterable: false,
    width: '250px',
  },

  {
    text: 'Device name',
    value: 'DEVICE_NAME',
    filterable: false,
    width: '250px',
  },
  {
    text: 'Device type',
    value: 'DEVICE_TYPE',
    filterable: false,
    sortable: false,
    width: '150px',
  },
  {
    text: 'Direction of passage',
    value: 'IS_ENTRY',
    filterable: false,
    sortable: false,
    width: '200px',
  },
  {
    text: 'Request ID',
    value: 'REQUEST_ID',
    sortable: false,
    width: '100px',
  },
  {
    text: 'Request SID',
    value: 'REQUEST_SID',
    sortable: false,
    width: '200px',
  },
  {
    text: 'System name',
    value: 'SYSTEM_NAME',
    filterable: false,
    sortable: false,
    width: '200px',
  },
  {
    text: 'Pass inside status',
    value: 'INSIDE_TEXT',
    filterable: false,
    sortable: false,
    width: '250px',
  },
  {
    text: 'Date and time the records were created',
    value: 'DATE',
    filterable: false,
    width: '200px',
  },
  {
    text: 'Error message',
    value: 'MESSAGE',
    filterable: false,
    sortable: false,
    width: '200px',
  },
  {
    text: 'Passage status',
    value: 'IS_PASSED',
    filterable: false,
    sortable: false,
    width: '180px',
  },
];

export const filterModel = [
  {
    name: 'OBJECT_ID',
    type: 'autocomplete',
    data: [],
    items: [],
    label: 'Object of visit',
  },
  {
    name: 'DEVICE_TYPE',
    type: 'select',
    data: null,
    items: DEVICE_TYPE,
    label: 'Device type',
  },
  {
    name: 'DEVICE_SID',
    type: 'autocomplete',
    data: [],
    items: [],
    label: 'Device name',
  },

  // {
  //     name: "IS_ENTRY",
  //     type: "select",
  //     data: [],
  //     items: DEVICE_IS_ENTRY,
  //     label: "Направление прохода",
  // },
  {
    name: 'SYSTEM_ID',
    type: 'autocomplete',
    data: [],
    items: [],
    label: 'External system',
  },
  {
    name: 'INSIDE',
    type: 'select',
    data: null,
    items: FLAG_INSIDE,
    label: 'Pass inside status',
  },
  {
    name: 'DATE_START',
    type: 'date-time-picker',
    data: dateHelpers.getMonthAgo().toDate(),
    label: 'Date and time the records were created from',
  },
  {
    name: 'DATE_END',
    type: 'date-time-picker',
    data: dateHelpers.getToday().endOf('day').toDate(),
    label: 'Date and time the records were created till',
  },
  {
    name: 'IS_PASSED',
    type: 'select',
    data: null,
    items: FLAG_IS_PASSED,
    label: 'Passage status',
  },
  {
    name: 'REQUEST_ID',
    type: 'text-field',
    data: '',
    label: 'Request ID',
  },
  {
    name: 'LIMIT_S',
    type: 'text-field',
    altType: 'number',
    data: 1,
    label: 'Limit from',
  },
  {
    name: 'LIMIT_PO',
    type: 'text-field',
    altType: 'number',
    data: 1000,
    label: 'Limit to',
  },
];
