import addIcon from './add-icon';
import administration from './administration-icon';
import attention from './attention-icon';
import calendar from './calendar-icon';
import check from './check-icon.vue';
import checkboxOff from './checkboxOff-icon';
import checkboxOn from './checkboxOn-icon';
import closeIcon from './close-icon';
import closeCircle from './closeCircle-icon';
import copy from './copy-icon';
import deleteIcon from './delete-icon';
import document from './document-icon';
import down from './down-icon';
import download from './download-icon';
import edit from './edit-icon';
import equipment from './equipment-icon';
import filter from './filter-icon';
import hamburger from './hamburger-icon';
import hide from './hide-icon';
import home from './home-icon';
import left from './left-icon';
import linkIcon from './link-icon';
import location from './location-icon';
import lock from './lock-icon';
import logout from './logout-icon';
import mail from './mail-icon';
import paper from './paper-icon';
import paperDownload from './paperDownload-icon';
import paperReplaceIcon from './paperReplace-icon';
import paperUpload from './paperUpload-icon';
import password from './password-icon';
import plus from './plus-icon';
import profile from './profile-icon';
import question from './question-icon';
import radioOff from './radioOff-icon';
import radioOn from './radioOn-icon';
import right from './right-icon';
import search from './search-icon';
import settings from './settings-icon';
import showIcon from './show-icon';
import swapIcon from './swap-icon';
import telephone from './telephone-icon';
import unlock from './unlock-icon';
import up from './up-icon.vue';
import upload from './upload-icon.vue';
import user from './user-icon';

const SRP_ICONS = {
  add: { component: addIcon },
  administration: { component: administration },
  attention: { component: attention },
  calendar: { component: calendar },
  check: { component: check },
  checkboxOff: { component: checkboxOff },
  checkboxOn: { component: checkboxOn },
  close: { component: closeIcon },
  closeCircle: { component: closeCircle },
  copy: { component: copy },
  delete: { component: deleteIcon },
  document: { component: document },
  down: { component: down },
  download: { component: download },
  edit: { component: edit },
  equipment: { component: equipment },
  filter: { component: filter },
  hamburger: { component: hamburger },
  hide: { component: hide },
  home: { component: home },
  left: { component: left },
  link: { component: linkIcon },
  location: { component: location },
  lock: { component: lock },
  logout: { component: logout },
  mail: { component: mail },
  next: { component: down, props: { mode: 'next' } },
  paper: { component: paper },
  paperDownload: { component: paperDownload },
  paperReplace: { component: paperReplaceIcon },
  paperUpload: { component: paperUpload },
  password: { component: password },
  plus: { component: plus },
  prev: { component: down, props: { mode: 'prev' } },
  profile: { component: profile },
  question: { component: question },
  radioOff: { component: radioOff },
  radioOn: { component: radioOn },
  right: { component: right },
  search: { component: search },
  settings: { component: settings },
  show: { component: showIcon },
  swap: { component: swapIcon },
  telephone: { component: telephone },
  unlock: { component: unlock },
  up: { component: up },
  upload: { component: upload },
  user: { component: user },
};

export default SRP_ICONS;
