<template>
  <div>
    <h3 class="text-h3 mb-5">{{ $t("Visit data") }}</h3>
    <v-row>
      <v-col>
        <div v-for="field in translatedVisitDataInputs" :key="field.SID" class="sid-settings-field">
          <v-text-field
            v-model="field.NAME"
            :label="$t(field.LABEL) || ''"
            hide-details="auto"
            outlined
            :append-icon="field.ICON"
            disabled
            class="sid-settings-field__name"
          />
          <v-icon size="26" class="mx-1" color="grey">$link</v-icon>
          <v-text-field
            v-model="field.SID"
            hide-details="auto"
            outlined
            disabled
            class="sid-settings-field__value"
          />
        </div>
        <div
          v-for="field in translatedRequestFieldsConfig"
          :key="field.SID"
          class="sid-settings-field"
        >
          <v-switch
            v-model="field.IS_NOT_NULL"
            inset
            color="green"
            class="sid-settings-field__switch"
          />
          <v-text-field
            v-model="field.NAME"
            hide-details="auto"
            outlined
            class="sid-settings-field__name"
          />
          <v-icon size="26" class="mx-1" color="grey">$link</v-icon>
          <v-text-field
            v-model="field.SID"
            hide-details="auto"
            outlined
            disabled
            class="sid-settings-field__value"
          />
        </div>
        <request-statuses
          class="sid-settings-field__name"
          :default-pass-status="$store.getters.configDefaultPassStatus.VALUE"
          @update-state-fabric="
            (value) => updateStateFabric('CONFIG.DEFAULT_PASS_STATUS', value, null, 'system')"
        />
        <pass-types
          class="sid-settings-field__name"
          :is-settings="true"
          :prop-default-allowed-pass-type-value="$store.getters.configDefaultAllowedPassType.VALUE"
          :prop-default-pass-type-value="$store.getters.configDefaultPassType.VALUE"
          @update-allowed-pass-type="
            (value) => updateStateFabric('CONFIG.ALLOWED_PASS_TYPE', value, null, 'system')"
          @update-default-pass-type="
            (value) => updateStateFabric('CONFIG.DEFAULT_PASS_TYPE', value, null, 'system')"
        />
      </v-col>
    </v-row>
    <h3 class="text-h3 mb-5 mt-16">{{ $t("Common data") }}</h3>
    <v-row>
      <v-col>
        <v-form ref="configForm" v-model="configFormValid" @keyup.native.enter="saveConfigForm">
          <draggable v-model="commonConfigItems">
            <div v-for="field in commonConfigItems" :key="field.ID" class="sid-settings-field">
              <v-switch
                v-model="field.IS_NOT_NULL"
                inset
                color="red"
                class="sid-settings-field__switch"
              />
              <v-text-field
                v-model="field.NAME"
                :rules="[rules.required]"
                :label="$t('Field name')"
                hide-details="auto"
                outlined
                class="sid-settings-field__name"
              />
              <v-icon size="26" class="mx-1" color="grey">$link</v-icon>
              <v-text-field
                v-model="field.SID"
                :rules="[rules.required]"
                label="SID"
                hide-details="auto"
                outlined
                class="sid-settings-field__value"
              />
              <v-icon color="grey" class="ml-3" style="cursor: move">
                $hamburger
              </v-icon>
              <v-icon color="red" class="ml-3" @click="removeConfigItem(field)">
                $delete
              </v-icon>
            </div>
          </draggable>
        </v-form>
        <v-btn depressed color="primary" text small @click="addConfigItem">
          <v-icon size="17" class="mr-2">$add</v-icon>
          {{ $t("Add field") }}
        </v-btn>
      </v-col>
    </v-row>
    <v-divider class="my-5" />
    <v-btn depressed color="primary" class="mb-5" @click="saveConfigForm">
      {{ $t("Save") }}
    </v-btn>
    <v-btn depressed color="primary" class="ml-5 mb-5" outlined @click="cancelConfigForm">
      {{ $t("Cancel") }}
    </v-btn>
  </div>
</template>

<script>
import draggable from 'vuedraggable';
import api from '../../api';
import { COMMON_SET_LOADING } from '@/store/types/mutation-types';
import { SNACK_ADD_MESSAGE } from '@/store/types/action-types';
import RequestStatuses from '@/components/blocks/RequestStatuses.vue';
import PassTypes from '@/components/blocks/PassTypes.vue';
import updateStateMixin from '@/mixins/updateStateMixin.js';

export default {
  name: 'SettingsRequests',
  components: { draggable, RequestStatuses, PassTypes },
  mixins: [updateStateMixin],
  data() {
    return {
      generalConfigItems: [
        {
          SID: 'GREETER',
          NAME: 'Host',
          ICON: '$down',
        },
        {
          SID: 'DATE',
          NAME: 'Date of visit',
          ICON: '$calendar',
        },
        {
          SID: 'TIME_START, TIME_END',
          NAME: 'from 08:00 to 20:00',
          LABEL: 'Visit time',
          ICON: '',
        },
      ],
      requestFieldsConfig: [],
      commonConfigItems: [],
      configFormValid: false,
      rules: {
        required: (v) => !!v || '',
      },
    };
  },
  computed: {
    translatedVisitDataInputs() {
      return this.generalConfigItems.map((elem) => ({ ...elem, NAME: this.$t(elem.NAME) }));
    },
    translatedRequestFieldsConfig() {
      return this.requestFieldsConfig.map((elem) => (this.$i18n.locale === 'ru'
        ? { ...elem, NAME: elem.NAME }
        : { ...elem, NAME: this.$t(elem.NAME) }));
    },
  },
  mounted() {
    this.loadSettings();
  },
  methods: {
    loadSettings() {
      this.$store.commit(COMMON_SET_LOADING);
      api.requestsConfig.getAll().then((res) => {
        this.requestFieldsConfig.splice(0);
        this.commonConfigItems.splice(0);
        res.data.DATA.forEach((item) => this.filterRequestFieldsConfig({ ID: item.SID, ...item }));
        this.$store.commit(COMMON_SET_LOADING, false);
      });
    },
    filterRequestFieldsConfig(item) {
      if (item.IS_SYSTEM === true) {
        this.requestFieldsConfig.push(item);
      } else {
        this.commonConfigItems.push(item);
      }
    },
    addConfigItem() {
      if (this.$refs.configForm.validate()) {
        const orderingNumber = this.getLastOrderingNumber();
        this.commonConfigItems.push({
          ID: new Date().getUTCMilliseconds(),
          IS_NOT_NULL: false,
          IS_SYSTEM: false,
          NAME: '',
          ORDER: orderingNumber,
          SID: '',
        });
      }
    },
    getLastOrderingNumber() {
      if (this.commonConfigItems.length > 0) return this.commonConfigItems.at(-1).ORDER + 1;
      return this.requestFieldsConfig.at(-1).ORDER + 1;
    },
    removeConfigItem(field) {
      const fieldIndex = this.commonConfigItems.findIndex((item) => item.SID === field.SID);
      this.commonConfigItems.splice(fieldIndex, 1);
    },
    cancelConfigForm() {
      this.loadSettings();
      this.$refs.configForm.resetValidation();
    },
    saveConfigForm() {
      const configSids = [
        this.$store.getters.configDefaultPassStatus,
        this.$store.getters.configDefaultAllowedPassType,
        this.$store.getters.configDefaultPassType,
      ];

      if (this.$refs.configForm.validate()) {
        this.$store.commit(COMMON_SET_LOADING);
        api.requestsConfig
          .update(this.getSendParams(this.requestFieldsConfig.concat(this.commonConfigItems)))
          .then(() => api.config.update(configSids))
          .then((res) => {
            this.$store.commit(COMMON_SET_LOADING, false);
            this.$store.dispatch(SNACK_ADD_MESSAGE, {
              type: 'success',
              text: this.$i18n.locale === 'ru' ? res.data.MESSAGE : this.$t(res.data.MESSAGE),
            });
          })
          .catch((error) => {
            this.$store.commit(COMMON_SET_LOADING, false);
            this.$store.dispatch(
              SNACK_ADD_MESSAGE,
              this.$t(error.response.data.MESSAGE) || this.$t('Unknown error!'),
            );
          });
      }
    },
    getSendParams(configItems) {
      const sendParams = [];
      configItems.forEach((item) => sendParams.push({
        SID: item.SID,
        NAME: item.NAME,
        IS_NOT_NULL: item.IS_NOT_NULL,
      }));
      return sendParams;
    },
  },
};
</script>

<style lang="scss">
.sid-settings-field {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  position: relative;
  &__name {
    max-width: 369px;
    width: 100%;
  }
  &__value {
    max-width: 350px;
    width: 100%;
  }
  &__switch {
    position: absolute;
    top: 9px;
    left: -55px;
    margin: 0;
  }
}
</style>
