<template>
  <v-dialog :value="showDialog" max-width="580" @click:outside="closeDialog">
    <v-card>
      <v-card-actions class="justify-end">
        <v-icon size="16" class="mr-n3" @click="closeDialog">
          $close
        </v-icon>
      </v-card-actions>
      <v-card-title class="justify-center pt-0">
        <slot name="title">
          {{ $t("Confirm deletion?") }}
        </slot>
      </v-card-title>
      <v-card-text class="d-flex justify-center text-center secondary--text">
        <slot />
      </v-card-text>
      <v-card-actions class="justify-center pb-12">
        <v-btn class="px-7 py-3 mx-4" color="primary" outlined depressed @click="closeDialog">
          {{ $t("Cancel") }}
        </v-btn>
        <v-btn
          class="white--text px-7 py-3 mx-4"
          color="red"
          :loading="loading"
          depressed
          @click="$emit('confirm')"
        >
          <slot name="confirmBtn">
            {{ $t("Delete") }}
          </slot>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'ConfirmRemoveDialog',
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showDialog: true,
    };
  },

  methods: {
    closeDialog() {
      this.showDialog = false;
      setTimeout(() => this.$emit('closeDialog', false), 200);
    },
  },
};
</script>
