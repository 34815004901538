import Vue from 'vue';
import VueI18n from 'vue-i18n';
import VueCookies from 'vue-cookies';
import en from './lang/en';
import ru from './lang/ru';

Vue.use(VueI18n);

const lang = VueCookies.get('lang');

export default new VueI18n({
  locale: lang || 'en',
  formatFallbackMessages: true,
  silentFallbackWarn: true,
  silentTranslationWarn: true,
  messages: { en, ru },
});
