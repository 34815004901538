<template>
  <v-snackbar
    class="pt-3"
    top
    :timeout="2000"
    :value="Boolean(message)"
    @click.native="shiftMessage"
    @input="shiftMessage"
  >
    <span v-if="message && message.type === 'success'" class="mr-3 white rounded">
      <v-icon color="green"> $checkboxOn </v-icon>
    </span>
    {{ message && message.text }}
  </v-snackbar>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { SNACK_SHIFT_MESSAGE } from '../../store/types/action-types';

export default {
  name: 'SnacksList',
  computed: {
    ...mapState({
      message: (state) => state.snack.message,
    }),
  },
  methods: {
    ...mapActions({ shiftMessage: SNACK_SHIFT_MESSAGE }),
  },
};
</script>
