<template>
  <v-form ref="requestForm" v-model="requestFormValid" lazy-validation @keyup.native.enter="submit">
    <v-card :outlined="outlined">
      <v-card-title class="justify-space-between">
        <h3 :class="{ 'text-h3': !largeTitle, 'text-h2 py-1': largeTitle }">
          {{ isNewRequestForm ? $t("New request") : $t("Request №") + value.ID }}
        </h3>
      </v-card-title>
      <v-divider />
      <v-card-text class="text--primary pt-8">
        <h4 class="text-h3 mb-5">
          {{ $t("Visit data") }}
        </h4>
        <v-menu
          v-model="datePickerMenu"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template #activator="{ on, attrs }">
            <v-text-field
              v-model="formattedDatePickerDate"
              :rules="[rules.required]"
              readonly
              outlined
              v-bind="attrs"
              v-on="on"
            >
              <template #append>
                <v-icon class="pt-1" size="20">
                  $calendar
                </v-icon>
              </template>
              <template #label>
                {{ $t("Date of visit") }}<span class="red--text">*</span>
              </template>
            </v-text-field>
          </template>
          <v-date-picker
            v-model="datePickerDate"
            :allowed-dates="allowedDates"
            :first-day-of-week="1"
            no-title
            scrollable
            next-icon="$next"
            :locale="$i18n.locale"
            @input="datePickerInputHandler"
          />
        </v-menu>
        <div class="text-subtitle-2 text--secondary mb-2 mt-n1">
          {{ $t("Visit time") }}<span class="red--text">*</span>
        </div>
        <time-range-picker
          v-model="passageTime"
          class="mb-2"
          :text-field-props="{ rules: [rules.required, rules.period] }"
        />
        <template v-if="!(requestFieldsConfig.length > 0 || commonConfigItems.length > 0)">
          <v-skeleton-loader class="srp-field-skeleton mb-7" type="button" />
          <v-skeleton-loader class="srp-field-skeleton mb-7" type="button" />
          <v-skeleton-loader class="srp-field-skeleton mb-7" type="button" />
          <v-skeleton-loader class="srp-field-skeleton mb-7" type="button" />
        </template>
        <request-form-field
          v-for="field in requestFieldsConfig"
          :key="field.SID"
          v-model="requestForm.data[field.SID]"
          :field="field"
        />
        <consent-personal-data-checkbox />
        <h4 v-if="commonConfigItems.length > 0" class="text-h3 mb-5">
          {{ $t("general information") }}
        </h4>
        <request-form-field
          v-for="field in commonConfigItems"
          :key="field.SID"
          v-model="requestForm.data[field.SID]"
          :field="field"
          :rules="rules"
        />
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <v-btn
          color="primary"
          :disabled="!requestFormValid"
          :loading="requestFormLoading"
          @click="submit"
        >
          {{ submitText ? submitText : isNewRequestForm ? $t("Register") : $t("Save") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-form>
</template>

<script>
import moment from 'moment';
import api from '@/api';
import { SNACK_ADD_MESSAGE } from '@/store/types/action-types';
import RequestFormField from './RequestFormField';
import TimeRangePicker from '@/components/blocks/TimeRangePicker';
import ConsentPersonalDataCheckbox from '@/components/blocks/ConsentPersonalDataCheckbox.vue';

export default {
  name: 'RequestForm',
  components: {
    TimeRangePicker,
    RequestFormField,
    ConsentPersonalDataCheckbox,
  },
  props: {
    value: { type: Object, default: () => {} },
    user: { type: Object, default: () => {} },
    submitText: { type: String, default: null },
    hideClose: Boolean,
    hideUser: Boolean,
    outlined: Boolean,
    largeTitle: Boolean,
    preventSubmit: Boolean,
  },
  data() {
    return {
      passageTime: '',
      usersShortList: [],
      requestsConfig: [],
      requestFormLoading: false,
      requestFormValid: false,
      requestForm: {},
      datePickerMenu: false,
      datePickerDate: '',
      rules: {
        required: (v) => !!v || this.$t('Required field'),
        period: (v) => {
          const period = v.match(/(\d{2}:\d{2})/g);
          if (!period) return false;
          if (!(period.length >= 2)) return false;
          const firstDate = moment(
            `${this.formattedDatePickerDate} ${period[0]}`,
            'DD.MM.YYYY HH:mm',
          );
          const secondDate = moment(
            `${this.formattedDatePickerDate} ${period[1]}`,
            'DD.MM.YYYY HH:mm',
          );
          if (!firstDate.isValid() || !secondDate.isValid()) return this.$t('Wrong time to visit');
          if (!secondDate.isAfter(firstDate)) return this.$t('Incorrect visit period');
          if (this.isNewRequestForm && secondDate.isBefore(moment())) { return this.$t('You have entered a past date'); }
          return true;
        },
      },
    };
  },
  computed: {
    requestFieldsConfig() {
      return this.requestsConfig.filter((item) => item.IS_SYSTEM === true);
    },
    commonConfigItems() {
      return this.requestsConfig.filter((item) => item.IS_SYSTEM === false);
    },
    formattedDatePickerDate() {
      return this.datePickerDate ? moment(this.datePickerDate).format('DD.MM.YYYY') : '';
    },
    isNewRequestForm() {
      return Boolean(this.value && !this.value.ID);
    },
    canChangeUser() {
      return !(this.user && this.user.ID);
    },
  },
  watch: {
    value() {
      this.$nextTick(() => this.initForm());
    },
  },
  mounted() {
    this.initForm();
  },
  methods: {
    datePickerInputHandler() {
      this.datePickerMenu = false;
      /* При изменении даты обновляет поле время для валидации */
      const oldTime = this.passageTime;
      this.passageTime = '';
      this.$nextTick(() => (this.passageTime = oldTime));
    },
    allowedDates: (val) => moment(val).isAfter(moment().add(-1, 'days')),
    createRequestForm(requestModel = {}) {
      return {
        state: requestModel.STATE || null,
        user_id: null,
        date_begin: '',
        date_end: '',
        data: {},
        multi_pass: null,
      };
    },
    initForm() {
      this.resetForm();
      if (this.user && this.user.ID) {
        this.usersShortList.push({ value: this.user.ID, text: this.user.NAME });
        this.requestForm.user_id = this.user.ID;
      } else if (!this.usersShortList.length > 0) {
        this.loadUsersShortList();
      } else if (this.value.USER_NAME) {
        const userByName = this.usersShortList.filter(
          (user) => user.text === this.value.USER_NAME,
        );
        if (userByName.length > 0) {
          this.requestForm.user_id = userByName[0].value;
        }
      }

      if (!this.requestsConfig.length > 0) {
        this.loadRequestsConfig();
      } else {
        this.requestsConfig.forEach((item) => {
          this.requestForm.data[item.SID] = this.value.DATA && this.value.DATA[item.SID] ? this.value.DATA[item.SID] : '';
        });
      }
      this.datePickerDate = this.value.S_DATE_DMY
        ? moment(this.value.S_DATE_DMY, 'DD.MM.YYYY').toISOString()
        : moment().toISOString();
      // TODO: сделать другое решение для хранения диапазона (сделать массив [sDate, eDate])
      this.passageTime = this.value.S_DATE_HI
        ? `с ${this.value.S_DATE_HI} до ${this.value.E_DATE_HI}`
        : '';
    },
    resetForm() {
      this.$refs.requestForm.resetValidation();
      this.requestForm = this.createRequestForm(this.value);
    },
    loadUsersShortList() {
      api.users.getShortList().then((res) => {
        if (res.data.DATA) {
          this.usersShortList.splice(0);
          res.data.DATA.forEach((item) => {
            if (this.value.USER_NAME && this.value.USER_NAME === item.NAME) {
              this.requestForm.user_id = item.ID;
            }

            this.usersShortList.push({ value: item.ID, text: item.NAME });
          });
        }
      });
    },
    loadRequestsConfig() {
      api.requestsConfig.getAll().then((res) => {
        if (!res.data.DATA) return false;

        this.requestsConfig.splice(0);
        res.data.DATA.forEach((item) => {
          this.requestForm.data[item.SID] = this.value.DATA && this.value.DATA[item.SID] ? this.value.DATA[item.SID] : '';
          this.requestsConfig.push(item);
        });
      });
    },
    getStartDate() {
      const time = this.passageTime.match(/(\d{2}:\d{2})/g)[0];
      return `${this.formattedDatePickerDate} ${time}`;
    },
    getEndDate() {
      const time = this.passageTime.match(/(\d{2}:\d{2})/g)[1];
      return `${this.formattedDatePickerDate} ${time}`;
    },
    submit() {
      if (!this.$refs.requestForm.validate()) {
        this.$store.dispatch(
          SNACK_ADD_MESSAGE,
          this.$t('Validation error. Check the correctness of the entered data.'),
        );
        return false;
      }

      this.requestForm.date_begin = this.getStartDate();
      this.requestForm.date_end = this.getEndDate();

      if (this.preventSubmit) return this.$emit('submit-form', this.requestForm);

      this.requestFormLoading = true;
      if (this.isNewRequestForm) {
        api.requests
          .create(this.requestForm)
          .then((res) => {
            this.requestFormLoading = false;
            this.$store.dispatch(SNACK_ADD_MESSAGE, {
              type: 'success',
              text: this.$t(res.data.MESSAGE),
            });
            this.$emit('input', this.requestForm);
            this.$emit('after-send-form');
          })
          .catch((error) => {
            this.requestFormLoading = false;
            this.$store.dispatch(
              SNACK_ADD_MESSAGE,
              this.$t(error.response.data.MESSAGE) || this.$t('Unknown error!'),
            );
          });
      } else {
        api.requests
          .update(this.requestForm, this.value.ID)
          .then((res) => {
            this.requestFormLoading = false;
            this.$store.dispatch(SNACK_ADD_MESSAGE, {
              type: 'success',
              text: this.$t(res.data.MESSAGE),
            });
            this.$emit('input', this.requestForm);
            this.$emit('after-send-form');
          })
          .catch((error) => {
            this.requestFormLoading = false;
            this.$store.dispatch(
              SNACK_ADD_MESSAGE,
              this.$t(error.response.data.MESSAGE) || this.$t('Unknown error!'),
            );
          });
      }
    },
  },
};
</script>
