import { USER_STATUSES, USER_ROLES } from '@/constants';

export const tableHeaders = [
  {
    text: 'ID',
    value: 'ID',
    filterable: false,
    sortable: false,
    width: '80px',
  },
  {
    text: 'Name',
    value: 'NAME',
    cellClass: 'font-weight-medium',
    sortable: false,
  },
  {
    text: 'Login',
    value: 'LOGIN',
    sortable: false,
    width: '150px',
  },
  {
    text: 'Status',
    value: 'STATUS',
    filterable: false,
    sortable: false,
    width: '150px',
  },
  {
    text: 'Role',
    value: 'ROLE',
    filterable: false,
    sortable: false,
    width: '150px',
  },
  {
    text: 'Description',
    value: 'DESCRIPTION',
    sortable: false,
  },
  {
    text: 'Actions',
    value: 'ACTIONS',
    align: 'end',
    filterable: false,
    sortable: false,
  },
];

export const filterModel = [
  {
    name: 'STATUS',
    type: 'select',
    data: null,
    items: USER_STATUSES,
    label: 'Status',
  },
  {
    name: 'ROLE',
    type: 'select',
    data: null,
    items: USER_ROLES,
    label: 'Role',
  },
];
