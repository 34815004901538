<template>
  <div v-show="show">
    <v-card :style="`background-color: ${backgroundColor}`" class="mb-5 pa-5 d-flex flex-wrap" flat>
      <v-card
        v-for="(element, index) in translatedValue"
        :key="index"
        flat
        width="25%"
        :style="`background-color: ${backgroundColor}`"
      >
        <v-select
          v-if="element.type === 'multi-select' || element.type === 'select'"
          v-model="element.data"
          :multiple="element.type === 'multi-select'"
          :items="element.items"
          :label="element.label"
          :menu-props="{ offsetY: true }"
          class="ma-4 white"
          append-icon="$down"
          hide-details="auto"
          outlined
          dense
          clearable
          clear-icon="$closeCircle"
          @change="setDataFilter(element)"
        >
          <template #selection="{ item, index }">
            <span v-if="index === 0" class="v-select__selections-item">
              {{ item.text || item }}
            </span>
            <span v-if="index === 1" class="grey--text text-caption">
              &nbsp;(+{{ element.data.length - 1 }})
            </span>
          </template>
        </v-select>

        <v-text-field
          v-else-if="element.type === 'text-field'"
          v-model="element.data"
          :label="element.label"
          dense
          outlined
          hide-details="auto"
          clearable
          clear-icon="$closeCircle"
          class="ma-4 white"
          :type="element.altType ? element.altType : 'text'"
          :rules="numberRules"
          @change="setDataFilter(element)"
        />

        <date-time-picker
          v-else-if="element.type === 'date-time-picker'"
          v-model="element.data"
          :placeholder="element.label"
          class="px-4 my-4"
          dense
          @getDate="setDate"
          @closeCalendar="setDataFilter(element)"
        />
        <v-autocomplete
          v-else-if="element.type === 'autocomplete' || element.type === 'multi-autocomplete'"
          v-model="element.data"
          :items="element.items"
          :label="element.label"
          :multiple="element.type === 'multi-autocomplete'"
          outlined
          dense
          clearable
          clear-icon="$closeCircle"
          :menu-props="{ offsetY: true }"
          class="ma-4 white"
          append-icon="$down"
          hide-details="auto"
          @change="setDataFilter(element)"
        >
          <template #selection="{ item, index }">
            <span v-if="index === 0" class="v-select__selections-item">
              {{ item.text }}
            </span>
            <span v-if="index === 1" class="grey--text text-caption">
              (+{{ element.data.length - 1 }})
            </span>
          </template>
        </v-autocomplete>
        <div v-else-if="element.type === 'space'" />
      </v-card>

      <!-- <v-card style="width: 100%"></v-card> -->
      <v-btn depressed color="primary" class="ma-4" @click="getDataFilters">
        {{ $t("Apply") }}
      </v-btn>
      <v-btn
        v-if="showClearFilters"
        depressed
        color="primary"
        class="ma-4"
        text
        @click="clearFilter"
      >
        <v-icon size="18" class="mr-2">
          $close
        </v-icon>
        {{ $t("Clear") }}
      </v-btn>
    </v-card>
  </div>
</template>
<script>
import DateTimePicker from '@/components/blocks/DateTimePicker.vue';

export default {
  name: 'FilterComponent',
  components: {
    DateTimePicker,
  },
  props: {
    value: {
      type: Array,
      required: true,
    },
    backgroundColor: {
      type: String,
      default: '#eff6fa',
    },
    show: {
      type: Boolean,
      default: true,
    },
    data: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  data() {
    return {
      dataFilters: this.data,
      numberRules: [(v) => !!v, (v) => v >= 0, (v) => v <= 1000],
      time: '',
    };
  },
  computed: {
    showClearFilters() {
      let allFieldsIsClear = true;
      this.value.forEach((element) => {
        const { data } = element;
        if (typeof data === 'string' && data !== '') {
          allFieldsIsClear = false;
        } else if (Array.isArray(data) && data.length !== 0) {
          allFieldsIsClear = false;
        } else if (Number.isInteger(data)) {
          allFieldsIsClear = false;
        } else if (typeof data === 'object' && data !== null && data.length !== 0) {
          allFieldsIsClear = false;
        } else if (typeof data === 'boolean') {
          allFieldsIsClear = false;
        }
      });
      this.$emit('filterIsEmpty', allFieldsIsClear);
      return !allFieldsIsClear;
    },
    translatedValue() {
      return this.value.map((elem) => {
        let { items } = elem;
        if (elem.type.includes('select') || elem.type.includes('autocomplete')) {
          items = elem.items.map((item) => ({ ...item, text: this.$t(item.text) }));
        }
        return { ...elem, items, label: this.$t(elem.label) };
      });
    },
  },
  methods: {
    clearFilter() {
      const query = { ...this.$route.query };
      this.value.forEach((element) => {
        if (element.type === 'date-time-picker') {
          this.time = '';
          element.data = null;
        } else if (element.name === 'LIMIT_S') {
          element.data = 1;
        } else if (element.name === 'LIMIT_PO') {
          element.data = 1000;
        } else {
          element.data = [];
        }
        delete query[element.name?.toLowerCase()];
      });
      if (JSON.stringify(this.$route.query) !== JSON.stringify(query)) this.$router.replace({ query });
      this.getDataFilters();
    },
    setDataFilter(currentEl) {
      if (currentEl.altType) {
        if (currentEl.data?.match(/^0+/)) {
          currentEl.data = currentEl.data.replace(/^0+/, '');
        }

        if (currentEl.data < 1 || currentEl.data === '') {
          currentEl.data = 1;
        } else if (currentEl.data > 1000) {
          currentEl.data = 1000;
        }
      }

      const getDataFilterIndex = () => {
        for (let i = 0; i < this.dataFilters.length; i++) {
          if (currentEl.name === this.dataFilters[i].name) {
            if (currentEl.type === 'date-time-picker') {
              currentEl.data = this.time;
            }

            return i;
          }
        }
      };

      this.$set(this.dataFilters, getDataFilterIndex(), currentEl);
    },
    getDataFilters() {
      this.$emit('getDataFilters', this.dataFilters);
      this.$emit('applyFilter');
    },
    setDate(date) {
      this.time = date;
    },
  },
};
</script>

<style lang="scss">
.v-select__selections {
	flex-wrap: nowrap;
	&-item {
		text-overflow: ellipsis;
		overflow: hidden;
		white-space: nowrap;
	}
}
</style>
