<template>
  <v-row class="m-0 align-center fill-height">
    <v-col class="d-flex flex-column align-center">
      <v-skeleton-loader
        v-if="!userId"
        elevation="1"
        type="card-heading, divider, list-item-two-line, list-item, list-item, actions"
        width="100%"
        max-width="430"
      />
      <request-form
        v-if="userId"
        v-model="requestForm"
        :user="{ ID: userId }"
        hide-user
        :submit-text="$t('Send request')"
        hide-close
        outlined
        large-title
        prevent-submit
        style="width: 100%; max-width: 430px"
        @submit-form="submitRequestForm"
      />
      <v-dialog v-model="agreeDialog" persistent max-width="459">
        <v-card>
          <v-card-actions />
          <v-card-text class="justify-center d-flex pb-11">
            <div style="max-width: 340px">
              <div class="d-flex align-center mb-7">
                <v-checkbox
                  v-model="agree"
                  on-icon="$checkboxOn"
                  off-icon="$checkboxOff"
                  hide-details="auto"
                  class="mt-0 pt-0 mr-2 primary--text icon-inherit-color"
                  color="primary"
                />
                <span class="text--primary" style="font-size: 12px">
                  {{ $t("By clicking on the 'Continue' button, I consent to the") }}
                  <router-link :to="personalAgreeLink" target="_blank">
                    {{ $t("processing of personal data") }}
                  </router-link>
                  {{ $t("and accept") }}
                  <router-link :to="politicConfLink" target="_blank">
                    {{ $t("the Privacy Policy") }}
                  </router-link>
                </span>
              </div>
              <v-btn block :disabled="!agree" color="primary" @click="agreeDialog = !agreeDialog">
                {{ $t("Continue") }}
              </v-btn>
            </div>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-col>
    <lang-btn class="lang-btn mr-2" />
  </v-row>
</template>

<script>
import moment from 'moment';
import RequestForm from '@/components/forms/RequestForm/RequestForm';
import LangBtn from '@/components/blocks/LangBtn.vue';
import api from '@/api';
import { SNACK_ADD_MESSAGE } from '@/store/types/action-types';
import { COMMON_SET_LOADING } from '@/store/types/mutation-types';

export default {
  name: 'SelfRegistrationSelected',
  components: { RequestForm, LangBtn },
  props: {
    object: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      agreeDialog: false,
      agree: false,
      personalAgreeLink: '',
      politicConfLink: '',
      requestForm: {},
    };
  },

  computed: {
    userId() {
      return this.$store.getters.user.ID;
    },
  },

  mounted() {
    api.config.getFileLinks().then((res) => {
      if (res.data.DATA) {
        this.agreeDialog = res.data.DATA.personal_agree || res.data.DATA.politic_conf;
        this.personalAgreeLink = res.data.DATA.personal_agree
          ? `/${res.data.DATA.personal_agree}`
          : '';
        this.politicConfLink = res.data.DATA.politic_conf ? `/${res.data.DATA.politic_conf}` : '';
      }
    });
  },

  methods: {
    submitRequestForm(requestForm) {
      this.$store.commit(COMMON_SET_LOADING);
      const formData = new FormData();
      Object.keys(requestForm).forEach((key) => {
        let value = requestForm[key];
        switch (key) {
          case 's_date':
            value = moment(value).format('DD.MM.YYYY HH:mm:00');
            break;
          case 'e_date':
            value = moment(value).format('DD.MM.YYYY HH:mm:00');
            break;
          case 'data':
            value = JSON.stringify(value);
            break;
        }
        value = value === null ? '' : value;
        value = typeof value === 'boolean' ? Number(value) : value;
        formData.append(key.toLowerCase(), value);
      });
      formData.append('object_id', this.object.ID);
      api.requests
        .create(formData)
        .then((res) => {
          this.$store.dispatch(SNACK_ADD_MESSAGE, {
            type: 'success',
            text: this.$t(res.data.MESSAGE),
          });
          return this.$router.push(`/check-request/${res.data.DATA.CODE}`);
        })
        .catch((error) => {
          this.$store.dispatch(
            SNACK_ADD_MESSAGE,
            this.$t(error.response.data.MESSAGE) || this.$t('Unknown error!'),
          );
        })
        .finally(() => this.$store.commit(COMMON_SET_LOADING, false));
    },
  },
};
</script>

<style scoped lang="scss">
.lang-btn {
  position: absolute;
  top: 10px;
  right: 10px;
}
</style>
