import api from '../../api';
import { USER_CHECK_SESSION, USER_UPDATE_ACTIVITY } from '../types/action-types';
import { COMMON_SET_LOADING, USER_SET_MODEL } from '../types/mutation-types';

/** Модель юзера может быть: Админ, Бюро пропусков, Организация */
const stateModel = (user = {}) => ({
  ID: user.id || user.ID || null,
  LOGIN: user.LOGIN || '',
  NAME: user.NAME || '',
  ROLE: user.ROLE,
  LOGO: user.LOGO || '',
  FOOTER: user.FOOTER || '',
});

export default {
  state: {
    model: stateModel(),
    TOKEN: localStorage.getItem('token') || '',
  },
  mutations: {
    [USER_SET_MODEL](state, user) {
      state.model = stateModel(user);
    },
    setToken(state, token) {
      state.TOKEN = token || '';
    },
  },
  getters: {
    isLoggedIn: (state) => !!state.TOKEN,
    user: (state) => state.model,
    isOrganization: (state) => state.model.ROLE !== 1 && state.model.ROLE !== 0,
  },
  actions: {
    logout({ commit }) {
      commit(COMMON_SET_LOADING);
      api.root.logout().then(() => {
        localStorage.removeItem('token');
        commit(USER_SET_MODEL, {
          NAME: '',
          ROLE: null,
        });
        commit('setToken');
        commit(COMMON_SET_LOADING, false);
      });
    },
    logIn({ commit }, { username, password }) {
      commit(COMMON_SET_LOADING);
      api.root
        .login({ username, password })
        .then((res) => {
          if (res.data.DATA && res.data.DATA.USER) {
            commit(USER_SET_MODEL, res.data.DATA.USER);
            commit('setToken', res.data.DATA.TOKEN);
            localStorage.setItem('token', res.data.DATA.TOKEN);
          }
          commit(COMMON_SET_LOADING, false);
        })
        .catch(() => {
          commit(COMMON_SET_LOADING, false);
        });
    },
    [USER_CHECK_SESSION](context) {
      return new Promise((resolve) => {
        context.commit(COMMON_SET_LOADING);
        api.session.user().then((res) => {
          if (!res.data.DATA) return false;
          context.commit(USER_SET_MODEL, res.data.DATA);
          context.commit(COMMON_SET_LOADING, false);
          resolve(context.state.model);
        });
      });
    },
    [USER_UPDATE_ACTIVITY]() {
      api.session.updateActivity().then();
    },
  },
};
