import * as root from "./rest/root";
import * as session from "./rest/session";
import * as users from "./rest/users";
import * as config from "./rest/config";
import * as requestsConfig from "./rest/requests-config";
import * as requests from "./rest/requests";
import * as passages from "./rest/passages";
import * as zones from "./rest/zones";
import * as devices from "./rest/devices";
import * as objects from "./rest/objects";
import * as externalSystems from "./rest/external-systems";
import * as service from './rest/service.js';

export default {
  root,
  session,
  users,
  config,
  requestsConfig,
  requests,
  passages,
  zones,
  devices,
  objects,
  externalSystems,
  service,
};
