import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '../store';
import { USER_CHECK_SESSION } from '../store/types/action-types';

import RequestsPage from '../views/requests';
import LoginPage from '../views/LoginPage.vue';
import PassagesPage from '../views/passages';
import AdministrationLayout from '../views/administration';
import AccessZonesPage from '../views/administration/accessZones';
import EquipmentsPage from '../views/administration/equipments';
import ExternalSystemsPage from '../views/administration/externalSystems';
import UsersPage from '../views/administration/users';
import ObjectsPage from '../views/objects';
import SettingsPage from '../views/settings';
import SelfRegistrationPage from '../views/selfRegistration';
import CheckRequest from '../views/checkRequest';

import middleware from './middleware/index';
// import guest from "./middleware/guest";
import role from './middleware/role';
import auth from './middleware/auth';
import admin from './middleware/admin';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'SelfRegistration',
    component: SelfRegistrationPage,
  },
  {
    path: '/login',
    name: 'Login',
    meta: { middleware: [role] },
    component: LoginPage,
  },
  {
    path: '/administration',
    name: 'Administration',
    meta: { isNeedAuth: true, middleware: [admin] },
    component: AdministrationLayout,
    redirect: '/administration/users',
    children: [
      {
        path: 'users',
        name: 'Users',
        meta: { isNeedAuth: true, middleware: [admin], layout: 'admin' },
        component: UsersPage,
      },
      {
        path: 'external-systems',
        name: 'ExternalSystems',
        meta: { isNeedAuth: true, middleware: [admin], layout: 'admin' },
        component: ExternalSystemsPage,
      },
      {
        path: 'equipments',
        name: 'Equipments',
        meta: { isNeedAuth: true, middleware: [admin], layout: 'admin' },
        component: EquipmentsPage,
      },
      {
        path: 'access-zones',
        name: 'AccessZones',
        meta: { isNeedAuth: true, middleware: [admin], layout: 'admin' },
        component: AccessZonesPage,
      },
    ],
  },
  {
    path: '/objects',
    name: 'Objects',
    meta: { isNeedAuth: true, middleware: [admin], layout: 'admin' },
    component: ObjectsPage,
  },
  {
    path: '/requests',
    name: 'Requests',
    meta: { isNeedAuth: true, middleware: [auth], layout: 'admin' },
    component: RequestsPage,
  },
  {
    path: '/passages',
    name: 'Passages',
    meta: { isNeedAuth: true, middleware: [auth], layout: 'admin' },
    component: PassagesPage,
  },
  {
    path: '/settings',
    name: 'Settings',
    meta: { isNeedAuth: true, middleware: [admin], layout: 'admin' },
    component: SettingsPage,
  },
  {
    path: '/check-request/:id',
    name: 'checkRequest',
    component: CheckRequest,
  },
  {
    path: '/*',
    name: 'NotFound',
    redirect: '/',
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.meta.isNeedAuth) {
    if (!store.getters.isLoggedIn) {
      return next('/login');
    }
    if (store.state.user.model.ROLE === undefined) {
      return store.dispatch(USER_CHECK_SESSION).then(() => middleware(to, from, next));
    }
    return middleware(to, from, next);
  }
  if (store.getters.isLoggedIn && store.state.user.model.ROLE === undefined) {
    return store.dispatch(USER_CHECK_SESSION).then(() => middleware(to, from, next));
  }
  return middleware(to, from, next);
});

export default router;
