import axios from 'axios';

export const search = (params) => axios({
  url: '/passages',
  method: 'GET',
  params,
});

export const getShortList = (id) => axios({
  url: '/passages/short-list',
  method: 'GET',
  params: {
    request_id: id,
  },
});
