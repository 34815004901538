export const USER_STATUSES = [
  { text: 'Active', value: 1, colorClassName: 'green--text' },
  { text: 'Blocked', value: 2, colorClassName: 'red--text' },
];

export const USER_ROLES = [
  { text: 'Administrator', value: 0 },
  { text: 'Pass Bureau', value: 1 },
  { text: 'Organization (consideration)', value: 2 },
  { text: 'Organization (agreement)', value: 3 },
  { text: 'Creation of requests', value: 4 },
];

export const SYSTEM_STATUSES = [
  { text: 'Active', value: 1, colorClassName: 'green--text' },
  { text: 'Blocked', value: 2, colorClassName: 'red--text' },
];

export const SYSTEM_TYPE = [
  { text: 'Локальная', value: 0 },
  { text: 'Внешняя', value: 1 },
];

export const DEVICE_STATUSES = [
  { text: 'Active', value: 1, colorClassName: 'green--text' },
  { text: 'Blocked', value: 2, colorClassName: 'red--text' },
];

export const DEVICE_TYPE = [
  { text: 'Turnstile', value: 1 },
  { text: 'Electronic lock', value: 2 },
];

export const DEVICE_IS_EXTERNAL = [
  { text: 'Yes', value: true },
  { text: 'No', value: false },
];

// Используется для обработки данных пришедших с сервера
export const DEVICE_IS_ENTRY_in = [
  { text: 'Inside', value: true },
  { text: 'Out', value: false },
  { text: 'Not set', value: null },
];
// Используется для отправки фильтров
export const DEVICE_IS_ENTRY_out = [
  { text: 'Inside', value: 1 },
  { text: 'Out', value: 2 },
  { text: 'Not set', value: 0 },
];

// Используется для обработки данных пришедших с сервера
export const DEVICE_NO_PASS_EXIT_in = [
  { text: 'Yes', value: true },
  { text: 'No', value: false },
  { text: 'Systemic', value: null },
];
// Используется для отправки фильтров
export const DEVICE_NO_PASS_EXIT_out = [
  { text: 'Yes', value: 1 },
  { text: 'No', value: 2 },
  { text: 'Systemic', value: 0 },
];

// Используется для обработки данных пришедших с сервера
export const DEVICE_EXPIRED_EXIT_in = [
  { text: 'Yes', value: true },
  { text: 'No', value: false },
  { text: 'Systemic', value: null },
];
// Используется для отправки фильтров
export const DEVICE_EXPIRED_EXIT_out = [
  { text: 'Yes', value: 1 },
  { text: 'No', value: 2 },
  { text: 'Systemic', value: 0 },
];

// Используется для обработки данных пришедших с сервера
export const DEVICE_EXPIRED_OUT_in = [
  { text: 'Yes', value: true },
  { text: 'No', value: false },
  { text: 'Systemic', value: null },
];
// Используется для отправки фильтров
export const DEVICE_EXPIRED_OUT_out = [
  { text: 'Yes', value: 1 },
  { text: 'No', value: 2 },
  { text: 'Systemic', value: 0 },
];

export const ZONE_STATUSES = [
  { text: 'Active', value: 1, colorClassName: 'green--text' },
  { text: 'Blocked', value: 2, colorClassName: 'red--text' },
];

export const OBJECT_STATUSES = [
  { text: 'Active', value: 1, colorClassName: 'green--text' },
  { text: 'Blocked', value: 2, colorClassName: 'red--text' },
];

export const OBJECT_SYSTEM_ID = { local: 0 };

export const REQUEST_STATUSES = [
  {
    text: 'systemValue', value: null, colorClassName: 'grey--text', color: 'grey',
  },
  {
    text: 'Review', value: 0, colorClassName: 'grey--text', color: 'grey',
  },
  {
    text: 'Reviewed', value: 1, colorClassName: 'orange--text', color: 'orange',
  },
  {
    text: 'Agreed', value: 2, colorClassName: 'yellow--text', color: 'yellow',
  },
  {
    text: 'Confirmed', value: 3, colorClassName: 'green--text', color: 'green',
  },
  {
    text: 'Rejected', value: 4, colorClassName: 'red--text', color: 'red',
  },
];

export const REQUEST_IS_MULTIPASS = [
  { text: 'Yes', value: true, disabled: false },
  { text: 'No', value: false, disabled: false },
];

export const FLAG_INSIDE = [
  {
    text: 'Pass inside the building, entered through the device of the outer perimeter',
    value: 1,
  },
  {
    text: 'Pass inside the building, did not enter through the device of the outer perimeter',
    value: 2,
  },
  {
    text: 'The pass left the building through the outer perimeter device',
    value: 3,
  },
  {
    text: 'The pass left the building through the outer perimeter device after the expiration date',
    value: 4,
  },
];
export const FLAG_IS_PASSED = [
  { text: 'true', value: true },
  { text: 'false', value: false },
];

// Используется для отправки фильтров
export const EMAIL_SEND_ERR_OUT = [
  { text: 'Sending was not', value: 0 },
  { text: 'Successful sending', value: 1 },
  { text: 'Error', value: 2 },
];
// Используется для обработки данных пришедших с сервера
export const EMAIL_SEND_ERR_IN = [
  { text: 'Sending was not', value: null },
  { text: 'Successful sending', value: true },
  { text: 'Error', value: false },
];

export const ALLOWED_PASS_TYPES = {
  systemValue: 0,
  singlePass: 1,
  multiPass: 2,
  singleAndMultiPass: 3,
};
