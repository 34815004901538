import axios from 'axios';

export const login = ({ username, password }) => axios({
  url: '/login',
  method: 'POST',
  data: { username, password },
});

export const logout = () => axios({
  url: '/logout',
  method: 'POST',
});
