<template>
  <svg
    class="srp-icon"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.2266 3.86426C11.4797 3.86426 11.6889 4.05236 11.722 4.29641L11.7266 4.36426V13.4428C11.7266 13.7189 11.5027 13.9428 11.2266 13.9428C10.9734 13.9428 10.7642 13.7547 10.7311 13.5106L10.7266 13.4428V4.36426C10.7266 4.08812 10.9504 3.86426 11.2266 3.86426Z"
    />
    <path
      d="M13.591 10.3594C13.7858 10.1637 14.1024 10.163 14.2981 10.3578C14.476 10.5349 14.4928 10.8126 14.348 11.0087L14.2997 11.0649L11.5812 13.796C11.4035 13.9745 11.1247 13.9907 10.9286 13.8447L10.8724 13.796L8.15393 11.0649C7.95912 10.8692 7.95985 10.5526 8.15557 10.3578C8.33349 10.1807 8.6113 10.1652 8.8067 10.3109L8.86267 10.3594L11.2265 12.7341L13.591 10.3594Z"
    />
    <path
      d="M4.60742 2.05518C4.86055 2.05518 5.06975 2.24328 5.10286 2.48733L5.10742 2.55518V11.6337C5.10742 11.9098 4.88356 12.1337 4.60742 12.1337C4.35429 12.1337 4.14509 11.9456 4.11199 11.7015L4.10742 11.6337V2.55518C4.10742 2.27903 4.33128 2.05518 4.60742 2.05518Z"
    />
    <path
      d="M4.25331 2.2022C4.43099 2.02369 4.70982 2.00747 4.90589 2.15351L4.96205 2.2022L7.68057 4.93331C7.87538 5.12902 7.87465 5.4456 7.67893 5.64041C7.50101 5.81751 7.2232 5.83301 7.02779 5.68732L6.97183 5.63878L4.60753 3.26338L2.24353 5.63878C2.06643 5.8167 1.78869 5.83348 1.59262 5.68869L1.53642 5.64041C1.3585 5.46331 1.34172 5.18557 1.48651 4.9895L1.53479 4.93331L4.25331 2.2022Z"
    />
  </svg>
</template>

<script>
export default {
  name: 'SwapIcon',
};
</script>
