<template>
  <div>
    <v-row class="mb-5">
      <v-col cols="auto" align-self="center">
        <h2 class="text-h2">
          {{ $t("Access zones") }}
        </h2>
      </v-col>
      <v-col cols="auto">
        <v-btn depressed color="primary" @click="showNewZoneForm">
          <v-icon size="18" class="mr-2">
            $plus
          </v-icon>
          {{ $t("Add") }}
        </v-btn>
      </v-col>
      <v-spacer />
      <v-col cols="auto" class="d-flex flex-grow-1 justify-end">
        <v-text-field
          v-model="filterZones"
          dense
          outlined
          hide-details="auto"
          clearable
          clear-icon="$closeCircle"
          :label="$t('Name, description')"
          style="max-width: 320px"
        >
          <template #prepend-inner>
            <v-icon class="pt-1 mr-1 grey--text" size="20">
              $search
            </v-icon>
          </template>
        </v-text-field>
        <v-btn
          depressed
          class="ml-4"
          color="primary"
          :outlined="filterIsEmpty"
          width="150"
          @click="showFilters = !showFilters"
        >
          <template v-if="!showFilters">
            <v-icon class="mr-2" size="20">$filter</v-icon>
            {{ $t("Filters") }}
          </template>
          <template v-else>
            <v-icon class="mr-2" size="20">$up</v-icon>
            {{ $t("Collapse") }}
          </template>
        </v-btn>
      </v-col>
    </v-row>

    <filter-component
      v-model="filterModel"
      :show="showFilters"
      :data="filterModel"
      @getDataFilters="applyFilter"
      @filterIsEmpty="filterIsEmpty = $event"
    />

    <v-data-table
      :headers="translatedTableHeaders"
      :items="tableItems"
      sort-by="ID"
      :sort-desc="true"
      :items-per-page="-1"
      :loading="tableLoading"
      :header-props="{ 'sort-icon': '$swap' }"
      :loading-text="`${$t('Loading')}...`"
      class="table-striped"
      :search="filterZones"
      :no-data-text="$t('Data not found')"
      :no-results-text="$t('Data not found')"
      :footer-props="{
        'items-per-page-text': $t('Lines per page'),
        'items-per-page-options': [15, 25, 50, 100],
      }"
    >
      <template #[`item.ACTIONS`]="{ item }">
        <div class="d-inline-flex">
          <v-tooltip bottom color="primary">
            <template #activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                class="mr-4"
                color="primary"
                v-on="on"
                @click="showZoneDevices(item)"
              >
                $equipment
              </v-icon>
            </template>
            {{ $t("Equipment") }}
          </v-tooltip>
          <v-icon class="mr-4" color="red" @click="showRemoveZoneConfirm(item)">
            $delete
          </v-icon>
          <v-icon
            class="mr-4"
            :color="item.STATUS !== 2 ? 'red' : 'green'"
            @click="blockToggleZone(item)"
          >
            $lock
          </v-icon>
          <v-icon color="primary" @click="showEditZoneForm(item)">
            $edit
          </v-icon>
        </div>
      </template>
      <template #[`item.STATUS`]="{ item }">
        <span :class="item.STATUS !== 1 ? 'red--text' : 'green--text'">
          {{ $t(ZONE_STATUSES.find((elem) => elem.value == item.STATUS)?.text) }}
        </span>
      </template>
      <template #[`item.DATE_EDIT`]="{ item }">
        {{ dateHelpers.getDmyHmFormattedDate(item.DATE_EDIT) }}
      </template>
      <template #[`item.USER_ID`]="{ item }">
        {{ allUsers.find((elem) => elem.value == item.USER_ID)?.text }}
      </template>
    </v-data-table>

    <v-dialog v-model="zoneFormDialog" max-width="580">
      <v-card>
        <v-card-actions class="justify-end">
          <v-icon size="16" class="mr-n3" @click="zoneFormDialog = false">
            $close
          </v-icon>
        </v-card-actions>
        <v-card-title class="justify-center mb-6">
          <h3 class="text-h2">
            {{ isNewZoneForm ? $t("Adding an Access Zone") : $t("Access Zone Editing") }}
          </h3>
        </v-card-title>
        <v-card-text style="max-width: 410px; margin: 0 auto" class="text-center pb-16">
          <v-form ref="zoneForm" v-model="zoneFormValid" @keyup.native.enter="zoneFormSubmit">
            <v-text-field
              v-model="zoneForm.NAME"
              :rules="zoneFormRules.NAME"
              hide-details="auto"
              class="mb-5"
              outlined
            >
              <template #label>
                {{ $t("Name") }} <span class="red--text">*</span>
              </template>
            </v-text-field>
            <v-select
              v-model="zoneForm.STATUS"
              :items="translateZoneStatuses"
              :rules="zoneFormRules.STATUS"
              :menu-props="{ offsetY: true }"
              class="mb-5"
              append-icon="$down"
              hide-details="auto"
              outlined
            >
              <template #label>
                {{ $t("Status") }}<span class="red--text">*</span>
              </template>
            </v-select>
            <v-text-field
              v-if="!isNewZoneForm"
              v-model="zoneForm.DATE_EDIT"
              hide-details="auto"
              class="mb-5"
              :label="$t('Date and time of change')"
              outlined
              disabled
            />
            <v-select
              v-if="!isNewZoneForm"
              v-model="zoneForm.USER_ID"
              :items="allUsers"
              class="mb-5"
              :label="$t('The user who made the change')"
              hide-details="auto"
              outlined
              disabled
            />
            <v-textarea
              v-model="zoneForm.DESCRIPTION"
              :label="$t('Description')"
              class="mb-5"
              rows="4"
              row-height="30"
              hide-details="auto"
              outlined
            />
            <v-btn
              depressed
              block
              color="primary"
              :disabled="!zoneFormValid"
              @click="zoneFormSubmit"
            >
              {{ isNewZoneForm ? $t("Add") : $t("Save") }}
            </v-btn>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>

    <confirm-remove-dialog
      v-if="zoneRemoveConfirm"
      :loading="zoneRemoveLoading"
      @closeDialog="zoneRemoveConfirm = false"
      @confirm="removeZone"
    >
      <template #title>
        {{ $t("Delete access zone") }} «{{ zoneForm.NAME }}»?
      </template>
      <template #default>
        {{ $t("After deletion, it will be impossible to restore access zone data") }}.
      </template>
    </confirm-remove-dialog>

    <checkbox-list-dialog
      v-if="showZoneDevicesDialog"
      :objects="devices"
      :title="$t('Equipment')"
      :subtitle="zoneForm.NAME"
      :headers="[{ text: 'NAME', value: 'ZONE_NAME' }]"
      item-key="ZONE_ID"
      @closeDialog="showZoneDevicesDialog = false"
      @itemSelected="deviceToggle"
    />
  </div>
</template>

<script>
import moment from 'moment';
import api from '@/api';
import { COMMON_SET_LOADING } from '@/store/types/mutation-types';
import { SNACK_ADD_MESSAGE } from '@/store/types/action-types';
import dateHelpers from '@/helpers/dateHelpers';
import CheckboxListDialog from '@/components/blocks/CheckboxListDialog.vue';
import ConfirmRemoveDialog from '@/components/blocks/ConfirmRemoveDialog.vue';
import FilterComponent from '@/components/blocks/FilterComponent.vue';
import { tableHeaders, filterModel } from './data.accessZones';
import { USER_STATUSES, ZONE_STATUSES } from '@/constants';

export default {
  name: 'AccessZonesPage',
  components: { ConfirmRemoveDialog, CheckboxListDialog, FilterComponent },
  data() {
    return {
      filterModel,
      tableHeaders,
      dateHelpers,
      ZONE_STATUSES,

      allUsers: [],
      devices: [],
      filterIsEmpty: true,
      filterZones: '',
      isNewZoneForm: true,
      showFilters: false,
      showZoneDevicesDialog: false,
      tableItems: [],
      tableLoading: false,
      zoneRemoveConfirm: false,
      zoneRemoveLoading: false,
      zoneFormDialog: false,
      zoneFormValid: false,
      zoneForm: this.createZoneFormModel(),
      zoneFormRules: {
        NAME: [(v) => !!v || `${this.$t('You must fill in «Name»')}.`],
        STATUS: [(v) => !!v || v === 0 || `${this.$t('You need to fill in «Status»')}.`],
      },
    };
  },
  computed: {
    translatedTableHeaders() {
      return this.tableHeaders.map((elem) => ({ ...elem, text: this.$t(elem.text) }));
    },
    translateZoneStatuses() {
      return USER_STATUSES.map((elem) => ({ ...elem, text: this.$t(elem.text) }));
    },
  },
  mounted() {
    this.applyFilter();

    api.users.getAll().then((res) => {
      res.data.DATA.forEach((user) => this.allUsers.push({ text: user.NAME, value: user.ID }));
      this.filterModel[this.getIndexFromFilter('USER')].items = this.allUsers;
    });
  },
  methods: {
    applyFilter() {
      this.$store.commit(COMMON_SET_LOADING);
      const status = this.getValueFromFilter('STATUS');
      const user = this.getValueFromFilter('USER');
      const date_s = this.getValueFromFilter('DATE_S');
      const date_po = this.getValueFromFilter('DATE_PO');
      api.zones
        .getAll({
          status, user, date_s, date_po,
        })
        .then((res) => (this.tableItems = res.data.DATA))
        .finally(() => this.$store.commit(COMMON_SET_LOADING, false));
    },
    getValueFromFilter(field) {
      return this.filterModel.find((item) => item.name === field).data;
    },
    getIndexFromFilter(field) {
      return this.filterModel.findIndex((item) => item.name === field);
    },
    showNewZoneForm() {
      if (this.$refs.zoneForm) this.$refs.zoneForm.resetValidation();
      this.zoneForm = this.createZoneFormModel();
      this.isNewZoneForm = true;
      this.zoneFormDialog = true;
    },
    showEditZoneForm(zone) {
      if (!zone) return false;
      this.zoneForm = this.createZoneFormModel(zone);
      this.isNewZoneForm = false;
      this.zoneFormDialog = true;
    },
    showRemoveZoneConfirm(zone) {
      this.zoneForm = this.createZoneFormModel(zone);
      this.zoneRemoveConfirm = true;
    },
    showZoneDevices(zone) {
      if (!zone) return false;
      this.zoneForm = this.createZoneFormModel(zone);
      this.loadDevices().then(() => (this.showZoneDevicesDialog = true));
    },
    createZoneFormModel(zone = {}) {
      return {
        ID: zone.ID || null,
        DATE_EDIT: moment(zone.DATE_EDIT).format('DD.MM.YYYY HH:mm:ss') || '',
        NAME: zone.NAME || '',
        STATUS: zone.STATUS || 2,
        USER_ID: zone.USER_ID || null,
        DESCRIPTION: zone.DESCRIPTION || '',
      };
    },
    zoneFormSubmit() {
      if (this.$refs.zoneForm.validate()) {
        const formData = new FormData();
        Object.keys(this.zoneForm).forEach((key) => {
          let value = this.zoneForm[key];
          value = value === null ? '' : value;
          value = typeof value === 'boolean' ? Number(value) : value;
          formData.append(key.toLowerCase(), value);
        });

        this.$store.commit(COMMON_SET_LOADING);
        if (this.isNewZoneForm) {
          api.zones
            .create(formData)
            .then((res) => {
              this.zoneFormDialog = false;
              this.tableItems.push(res.data.DATA);
              this.$store.dispatch(SNACK_ADD_MESSAGE, {
                type: 'success',
                text: this.$i18n.locale === 'ru' ? res.data.MESSAGE : this.$t(res.data.MESSAGE),
              });
            })
            .catch((error) => {
              this.$store.dispatch(SNACK_ADD_MESSAGE, this.$t(error.response.data.MESSAGE));
            })
            .finally(() => this.$store.commit(COMMON_SET_LOADING, false));
        } else {
          api.zones
            .update(this.zoneForm.ID, formData)
            .then((res) => {
              this.zoneFormDialog = false;
              const zoneIndex = this.tableItems.findIndex((item) => item.ID === res.data.DATA.ID);
              Object.assign(this.tableItems[zoneIndex], res.data.DATA);
              this.$store.dispatch(SNACK_ADD_MESSAGE, {
                type: 'success',
                text: this.$i18n.locale === 'ru' ? res.data.MESSAGE : this.$t(res.data.MESSAGE),
              });
            })
            .catch((error) => {
              this.$store.dispatch(SNACK_ADD_MESSAGE, this.$t(error.response.data.MESSAGE));
            })
            .finally(() => this.$store.commit(COMMON_SET_LOADING, false));
        }
      }
    },
    blockToggleZone(zone) {
      if (!zone) return false;
      this.zoneForm = this.createZoneFormModel(zone);
      this.isNewZoneForm = false;
      this.zoneForm.STATUS = this.zoneForm.STATUS !== 2 ? 2 : 1;
      const formData = new FormData();
      Object.keys(this.zoneForm).forEach((key) => {
        let value = this.zoneForm[key];
        value = value === null ? '' : value;
        value = typeof value === 'boolean' ? Number(value) : value;
        formData.append(key.toLowerCase(), value);
      });
      this.$store.commit(COMMON_SET_LOADING);
      api.zones
        .update(this.zoneForm.ID, formData)
        .then((res) => {
          this.$store.commit(COMMON_SET_LOADING, false);
          const zoneIndex = this.tableItems.findIndex((item) => item.ID === res.data.DATA.ID);
          Object.assign(this.tableItems[zoneIndex], res.data.DATA);
          this.$store.dispatch(SNACK_ADD_MESSAGE, {
            type: 'success',
            text: this.$i18n.locale === 'ru' ? res.data.MESSAGE : this.$t(res.data.MESSAGE),
          });
        })
        .catch((error) => {
          this.$store.commit(COMMON_SET_LOADING, false);
          this.$store.dispatch(SNACK_ADD_MESSAGE, this.$t(error.response.data.MESSAGE));
        });
    },
    removeZone() {
      this.zoneRemoveLoading = true;
      api.zones
        .remove(this.zoneForm.ID)
        .then((res) => {
          if (res.data.DATA) {
            this.zoneRemoveConfirm = false;
            this.zoneRemoveLoading = false;
            const zoneIndex = this.tableItems.findIndex((item) => item.ID === this.zoneForm.ID);
            this.tableItems.splice(zoneIndex, 1);
            this.$store.dispatch(SNACK_ADD_MESSAGE, {
              type: 'success',
              text: this.$i18n.locale === 'ru' ? res.data.MESSAGE : this.$t(res.data.MESSAGE),
            });
          }
        })
        .catch((error) => {
          this.zoneRemoveConfirm = false;
          this.zoneRemoveLoading = false;
          this.$store.dispatch(
            SNACK_ADD_MESSAGE,
            this.$i18n.locale === 'ru'
              ? error.response.data.MESSAGE
              : this.$t(error.response.data.MESSAGE),
          );
        });
    },

    deviceToggle({ item, value }) {
      this.$store.commit(COMMON_SET_LOADING);
      api.zones
        .changeDevice({
          id: this.zoneForm.ID,
          device_id: item.ZONE_ID,
          is_bind: value,
        })
        .then(() => this.loadDevices())
        .finally(() => this.$store.commit(COMMON_SET_LOADING, false));
    },

    loadDevices() {
      return api.zones.getDevices(this.zoneForm.ID).then((res) => (this.devices = res.data.DATA));
    },
  },
};
</script>
