import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import SRP_ICONS from '../components/icons';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#0060AE',
      },
      dark: {
        disable: true,
      },
    },
  },
  icons: {
    values: SRP_ICONS,
  },
});
