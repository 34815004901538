import Vue from 'vue';
import Vuex from 'vuex';
import user from './modules/user';
import common from './modules/common';
import snack from './modules/snack';
import systemConfig from './modules/systemConfig.js';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    user,
    common,
    snack,
    systemConfig,
  },
});
