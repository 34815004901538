var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-row',{staticClass:"mb-5"},[_c('v-col',{attrs:{"cols":"auto","align-self":"center"}},[_c('h2',{staticClass:"text-h2"},[_vm._v(_vm._s(_vm.$t("Passages")))])]),_c('v-spacer'),_c('v-col',{staticClass:"d-flex flex-grow-1 justify-end",attrs:{"cols":"auto"}},[_c('v-text-field',{staticStyle:{"max-width":"320px"},attrs:{"dense":"","outlined":"","hide-details":"auto","clearable":"","clear-icon":"$closeCircle","label":_vm.$t('Full name, request ID, request SID')},scopedSlots:_vm._u([{key:"prepend-inner",fn:function(){return [_c('v-icon',{staticClass:"pt-1 mr-1 grey--text",attrs:{"size":"20"}},[_vm._v("$search")])]},proxy:true}]),model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-btn',{staticClass:"ml-4",attrs:{"depressed":"","color":"primary","outlined":_vm.filterIsEmpty,"width":"150"},on:{"click":function($event){_vm.showFilters = !_vm.showFilters}}},[(!_vm.showFilters)?[_c('v-icon',{staticClass:"mr-2",attrs:{"size":"20"}},[_vm._v("$filter")]),_vm._v(" "+_vm._s(_vm.$t("Filters"))+" ")]:[_c('v-icon',{staticClass:"mr-2",attrs:{"size":"20"}},[_vm._v("$up")]),_vm._v(" "+_vm._s(_vm.$t("Collapse"))+" ")]],2)],1)],1),_c('filter-component',{attrs:{"show":_vm.showFilters,"data":_vm.filterModel},on:{"getDataFilters":_vm.applyFilter,"filterIsEmpty":function($event){_vm.filterIsEmpty = $event}},model:{value:(_vm.filterModel),callback:function ($$v) {_vm.filterModel=$$v},expression:"filterModel"}}),_c('v-data-table',{staticClass:"table-striped",attrs:{"headers":_vm.translatedTableHeaders,"items":_vm.tableItems,"sort-by":"DATE","sort-desc":true,"header-props":{ 'sort-icon': '$swap' },"loading":_vm.tableLoading,"no-data-text":_vm.$t('Data not found'),"no-results-text":_vm.$t('Data not found'),"search":_vm.search,"footer-props":{
      'items-per-page-text': _vm.$t('Records per page') + ':',
      'items-per-page-options': [15, 25, 50, 100],
    }},scopedSlots:_vm._u([{key:`item.DATE`,fn:function({ item }){return [_c('span',[_vm._v(_vm._s(_vm.dateHelpers.getDmyHmFormattedDate(item.DATE)))])]}},{key:`item.IS_PASSED`,fn:function({ item }){return [(item.IS_PASSED)?_c('v-icon',[_vm._v("$check")]):_vm._e()]}},{key:`item.DEVICE_TYPE`,fn:function({ item }){return [_vm._v(" "+_vm._s(_vm.$t(_vm.DEVICE_TYPE.find((elem) => elem.value == item.DEVICE_TYPE)?.text))+" ")]}},{key:`item.IS_ENTRY`,fn:function({ item }){return [_c('span',{class:{
          'green--text': item.IS_ENTRY,
          'red--text': !item.IS_ENTRY,
        }},[_vm._v(" "+_vm._s(item.IS_ENTRY ? _vm.$t("Entry") : _vm.$t("Exit"))+" ")])]}}],null,true)})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }