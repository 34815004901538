import Vue from 'vue';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import i18n from './plugins/vueI18n';
import './plugins/vueMask';
import initInterceptors from './api/interceptors';
import VueCookies from 'vue-cookies';

Vue.config.productionTip = false;
initInterceptors();

Vue.use(VueCookies, { expires: '30d' });
new Vue({
  i18n,
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount('#app');
