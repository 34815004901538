<template>
  <div class="justify-center d-flex">
    <div v-if="requestLoading">
      <v-skeleton-loader
        type="image"
        width="230px"
        height="230px"
        class="mb-9 mt-9 srp-object-image-skeleton"
      />
      <v-skeleton-loader type="list-item-two-line" width="200" class="mb-5" />
      <v-skeleton-loader type="list-item-two-line" width="200" class="mb-5" />
      <v-skeleton-loader type="list-item-two-line" width="200" class="mb-5" />
    </div>
    <div v-else-if="visitorRequest.DATA" class="d-flex flex-column subtitle-1">
      <div class="text-center">
        <Vue-qr-code
          :value="$route.params.id"
          :width="$vuetify.breakpoint.mobile ? 150 : 300"
        />
      </div>
      <div class="text-center">
        <v-badge v-if="requestStatus.value < 3" color="grey" overlap>
          <template #badge>
            <v-tooltip bottom max-width="250px">
              <template #activator="{ on, attrs }">
                <div v-bind="attrs" style="cursor: default" v-on="on">?</div>
              </template>
              {{
                $t(
                  "Once the request is approved, the status of the request will change to Approved"
                )
              }}
            </v-tooltip>
          </template>
          <v-chip
            class="request-status"
            :color="requestStatus.value === 0 ? 'orange' : requestStatus?.color"
            text-color="white"
            label
          >
            {{ $t(requestStatus?.text) }}
          </v-chip>
        </v-badge>
        <v-chip
          v-else
          class="request-status"
          :color="requestStatus?.color"
          text-color="white"
          label
        >
          {{ $t(requestStatus?.text) }}
        </v-chip>
      </div>
      <div class="text-center primary--text font-weight-bold mt-3 mb-6">
        {{ visitorRequest.S_DATE_DMY }} {{ visitorRequest.S_DATE_HI }} - {{ E_DATE_HI }}
        {{ visitorRequest.E_DATE_HI }}
      </div>
      <div class="mb-4 d-flex align-center" style="max-width: 300px">
        <v-icon size="20" color="primary" class="mr-4">$location</v-icon>
        {{ visitorRequest.OBJECT_NAME || "-" }}
      </div>
      <div class="mb-4 d-flex align-center" style="max-width: 300px">
        <v-icon size="20" color="primary" class="mr-4">$unlock</v-icon>
        {{ visitorRequest.MULTI_PASS ? $t("Reusable pass") : $t("One time pass") }}
      </div>
      <div class="mb-4 d-flex align-center" style="max-width: 300px">
        <v-icon size="20" color="primary" class="mr-4">$profile</v-icon>
        {{ visitorRequest.DATA.V_NAME || "-" }}
      </div>
      <div class="mb-4 d-flex align-center" style="max-width: 300px">
        <v-icon size="20" color="primary" class="mr-4">$mail</v-icon>
        {{ visitorRequest.DATA.V_EMAIL || "-" }}
      </div>
      <div class="mb-4 d-flex align-center" style="max-width: 300px">
        <v-icon size="20" color="primary" class="mr-4">$telephone</v-icon>
        {{ visitorRequest.DATA.V_PHONE || "-" }}
      </div>
      <div class="mb-4 d-flex align-center" style="max-width: 300px">
        <v-icon size="20" color="primary" class="mr-4">$edit</v-icon>
        {{ visitorRequest.DATA.NOTICE || "-" }}
      </div>
      <div class="d-flex justify-center">
        <v-btn color="primary" @click="() => $router.go(0)">
          {{ $t("Update status") }}
        </v-btn>
      </div>
      <div class="d-flex justify-center mt-4">
        <v-btn v-if="isRouteLink" color="primary" @click="() => goToRoute()">
          {{
            $t("Show route")
          }}
        </v-btn>
      </div>
    </div>
    <lang-btn class="lang-btn mr-2" />
  </div>
</template>

<script>
import moment from 'moment';
import VueQrCode from 'vue-weblineindia-qrcode';
import api from '@/api';
import { REQUEST_STATUSES } from '@/constants';
import { SNACK_ADD_MESSAGE } from '@/store/types/action-types';
import LangBtn from '@/components/blocks/LangBtn.vue';

export default {
  name: 'CheckRequest',
  components: { VueQrCode, LangBtn },
  data() {
    return {
      visitorRequest: {},
      requestLoading: true,
      requestStatus: {},
      routeLink: null,
      moment,
    };
  },
  computed: {
    isLoggedIn() {
      return this.$store.state.user.model.ROLE !== undefined;
    },
    isRouteLink() {
      return !!this.routeLink;
    },
    E_DATE_HI() {
      if (
        moment(this.visitorRequest.DATE_END).format('DD.MM.YYYY') === this.visitorRequest.S_DATE_DMY
      ) {
        return '';
      }
      return moment(this.visitorRequest.DATE_END).format('DD.MM.YYYY');
    },
  },
  watch: {
    isLoggedIn(isLoggedIn) {
      if (isLoggedIn) this.getRequestData();
    },
  },
  mounted() {
    if (this.isLoggedIn) this.getRequestData();
  },
  beforeMount() {
    if (!this.isLoggedIn) {
      this.$store.dispatch('logIn', {
        username: 'user_role4',
        password: 'user_role4',
      });
    }
  },
  methods: {
    getRequestData() {
      api.requests
        .search({ code: this.$route.params.id })
        .then((res) => {
          this.routeLink = res.data.DATA[0].ROUTE_LINK;
          this.$cookies.set('lang', res.data.DATA[0].LANG_REQ);
          this.$i18n.locale = res.data.DATA[0].LANG_REQ;
          if (res.data.DATA.length < 1) {
            this.$store.dispatch(
              SNACK_ADD_MESSAGE,
              `${this.$t('Request with code')} ${this.$route.params.id} ${this.$t('not found')}`,
            );
            setTimeout(() => this.$router.push('/'), 2000);
          } else {
            this.visitorRequest = res.data.DATA[0];
            this.requestStatus = REQUEST_STATUSES.filter(
              (status) => status.value === this.visitorRequest.STATE,
            )[0];
          }
        })
        .catch((error) => {
          this.$store.dispatch(
            SNACK_ADD_MESSAGE,
            this.$t(error.response.data.MESSAGE) || this.$t('Required field'),
          );
          setTimeout(() => this.$router.push('/'), 2000);
        })
        .finally(() => (this.requestLoading = false));
    },
    goToRoute() {
      window.open(this.routeLink, '_blank');
    },
  },
};
</script>

<style scoped lang="scss">
.lang-btn {
  position: absolute;
  top: 10px;
  right: 10px;
}
</style>
