<template>
  <div class="d-flex align-center fill-height">
    <div class="container">
      <v-row>
        <v-col>
          <v-form ref="form" v-model="valid" class="login-form" @keyup.native.enter="submit">
            <div class="d-flex justify-center mb-10">
              <v-img max-height="80" max-width="80" src="@/assets/images/logo.svg" />
            </div>
            <h4 class="text-h4 mb-5 text-center">
              {{ $t("Login to your personal account") }}
            </h4>
            <v-text-field
              v-model="login"
              :label="$t('Username')"
              :rules="loginRules"
              hide-details="auto"
              outlined
              required
              class="mb-3"
            />

            <v-text-field
              v-model="password"
              :append-icon="showPassword ? '$show' : '$hide'"
              :type="showPassword ? 'text' : 'password'"
              :rules="passwordRules"
              :label="$t('Password')"
              hide-details="auto"
              outlined
              required
              class="mb-5"
              @click:append="showPassword = !showPassword"
            />

            <v-btn :disabled="!valid" depressed block color="primary" @click="submit">
              {{ $t("Login") }}
            </v-btn>
          </v-form>
        </v-col>
      </v-row>
      <confirm-wrong-login-data v-if="!isCorrectLogPass" @closeDialog="isCorrectLogPass = true" />
    </div>
  </div>
</template>
<script>
import ConfirmWrongLoginData from '../components/blocks/ConfirmWrongLoginData.vue';
import api from '@/api';

export default {
  name: 'LoginPage',
  components: { ConfirmWrongLoginData },
  data() {
    return {
      showPassword: false,
      valid: false,
      loginRules: [(v) => !!v || `${this.$t('You need to fill in «Login»')}.`],
      passwordRules: [(v) => !!v || `${this.$t('You must enter «Password»')}.`],
      login: '',
      password: '',
      isCorrectLogPass: true,
    };
  },
  methods: {
    submit() {
      if (this.$refs.form.validate()) {
        this.$store.dispatch('logIn', {
          username: this.login,
          password: this.password,
        });
        api.root
          .login({ username: this.login, password: this.password })
          .then()
          .catch((error) => {
            if (error) this.isCorrectLogPass = false;
          });
      }
    },
  },
};
</script>

<style>
.login-form {
  width: 100%;
  max-width: 350px;
  margin: 0 auto;
}
</style>
