<template>
  <div>
    <v-row class="mb-5">
      <v-col cols="auto" align-self="center">
        <h2 class="text-h2">{{ $t("Passages") }}</h2>
      </v-col>
      <v-spacer />
      <v-col cols="auto" class="d-flex flex-grow-1 justify-end">
        <v-text-field
          v-model="search"
          dense
          outlined
          hide-details="auto"
          clearable
          clear-icon="$closeCircle"
          :label="$t('Full name, request ID, request SID')"
          style="max-width: 320px"
        >
          <template #prepend-inner>
            <v-icon class="pt-1 mr-1 grey--text" size="20">$search</v-icon>
          </template>
        </v-text-field>
        <v-btn
          depressed
          class="ml-4"
          color="primary"
          :outlined="filterIsEmpty"
          width="150"
          @click="showFilters = !showFilters"
        >
          <template v-if="!showFilters">
            <v-icon class="mr-2" size="20">$filter</v-icon>
            {{ $t("Filters") }}
          </template>
          <template v-else>
            <v-icon class="mr-2" size="20">$up</v-icon>
            {{ $t("Collapse") }}
          </template>
        </v-btn>
      </v-col>
    </v-row>

    <filter-component
      v-model="filterModel"
      :show="showFilters"
      :data="filterModel"
      @getDataFilters="applyFilter"
      @filterIsEmpty="filterIsEmpty = $event"
    />

    <v-data-table
      :headers="translatedTableHeaders"
      :items="tableItems"
      sort-by="DATE"
      :sort-desc="true"
      :header-props="{ 'sort-icon': '$swap' }"
      :loading="tableLoading"
      :no-data-text="$t('Data not found')"
      :no-results-text="$t('Data not found')"
      :search="search"
      :footer-props="{
        'items-per-page-text': $t('Records per page') + ':',
        'items-per-page-options': [15, 25, 50, 100],
      }"
      class="table-striped"
    >
      <template #[`item.DATE`]="{ item }">
        <span>{{ dateHelpers.getDmyHmFormattedDate(item.DATE) }}</span>
      </template>
      <template #[`item.IS_PASSED`]="{ item }">
        <v-icon v-if="item.IS_PASSED">$check</v-icon>
      </template>
      <template #[`item.DEVICE_TYPE`]="{ item }">
        {{ $t(DEVICE_TYPE.find((elem) => elem.value == item.DEVICE_TYPE)?.text) }}
      </template>
      <template #[`item.IS_ENTRY`]="{ item }">
        <span
          :class="{
            'green--text': item.IS_ENTRY,
            'red--text': !item.IS_ENTRY,
          }"
        >
          {{ item.IS_ENTRY ? $t("Entry") : $t("Exit") }}
        </span>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import moment from 'moment/moment';
import dateHelpers from '@/helpers/dateHelpers';
import api from '@/api';
import { COMMON_SET_LOADING } from '@/store/types/mutation-types';
import { tableHeaders, filterModel } from './data.passages';
import { DEVICE_TYPE } from '@/constants';
import FilterComponent from '@/components/blocks/FilterComponent.vue';

export default {
  name: 'PassagesPage',
  components: { FilterComponent },
  data() {
    return {
      DEVICE_TYPE,
      dateHelpers,
      tableHeaders,
      filterModel,
      dataFilters: FilterComponent.props.data.type,
      filterDateStart: null,
      filterDateEnd: null,
      filterByStatus: '',
      filterIsEmpty: false,
      search: '',
      organizations: [],
      showFilters: false,
      tableItems: [],
      tableLoading: false,
      tableCurrentItems: [],
    };
  },
  computed: {
    translatedTableHeaders() {
      return this.tableHeaders.map((elem) => ({ ...elem, text: this.$t(elem.text) }));
    },
  },
  mounted() {
    if (this.$route.query.request_id) {
      filterModel[this.getIndexFromFilter('REQUEST_ID')].data = this.$route.query.request_id;
      filterModel[this.getIndexFromFilter('DATE_START')].data = null;
      filterModel[this.getIndexFromFilter('DATE_END')].data = null;
    }
    this.applyFilter();
    api.objects.getAll().then((res) => {
      res.data.DATA.forEach((object) => {
        filterModel[this.getIndexFromFilter('OBJECT_ID')]?.items.push({
          text: object.NAME,
          value: object.ID,
        });
      });
    });
    api.devices.getAll().then((res) => {
      res.data.DATA.forEach((device) => {
        filterModel[this.getIndexFromFilter('DEVICE_SID')]?.items.push({
          text: device.NAME,
          value: device.SID,
        });
      });
    });
    api.externalSystems.getAll().then((res) => {
      res.data.DATA.forEach((system) => {
        filterModel[this.getIndexFromFilter('SYSTEM_ID')]?.items.push({
          text: system.NAME,
          value: system.ID,
        });
      });
    });
  },
  methods: {
    applyFilter(data) {
      this.dataFilters = data;
      this.$store.commit(COMMON_SET_LOADING);
      const object_id = this.getValueFromFilter('OBJECT_ID');
      const device_sid = this.getValueFromFilter('DEVICE_SID');
      const device_type = this.getValueFromFilter('DEVICE_TYPE');
      const request_id = this.getValueFromFilter('REQUEST_ID');
      const system = this.getValueFromFilter('SYSTEM_ID');
      const is_inside = this.getValueFromFilter('INSIDE');
      const date_s = this.getDateTime('DATE_START');
      const date_po = this.getDateTime('DATE_END');
      const is_passed = this.getValueFromFilter('IS_PASSED');
      const limit_s = this.getValueFromFilter('LIMIT_S');
      const limit_po = this.getValueFromFilter('LIMIT_PO');

      api.passages
        .search({
          object_id,
          device_sid,
          device_type,
          request_id,
          system,
          // TODO: костыль для обхода типов в БД решить в сентябре с ДБ и беком
          is_inside: typeof is_inside === 'boolean' ? Number(is_inside) : is_inside,
          date_s,
          date_po,
          // TODO: костыль для обхода типов в БД решить в сентябре с ДБ и беком
          is_passed: typeof is_passed === 'boolean' ? Number(is_passed) : is_passed,
          limit_s: +limit_s - 1,
          limit_po,
        })
        .then((res) => (this.tableItems = res.data.DATA))
        .finally(() => this.$store.commit(COMMON_SET_LOADING, false));
    },

    getValueFromFilter(field) {
      return (this.dataFilters || this.filterModel)?.find((item) => item.name === field).data;
    },
    getIndexFromFilter(field) {
      return this.filterModel.findIndex((item) => item.name === field);
    },
    getDateTime(field) {
      const dateTime = this.getValueFromFilter(field);
      return dateTime && moment(dateTime).format('YYYY-MM-DD HH:mm:00');
    },
  },
};
</script>
