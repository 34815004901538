import dateHelpers from '@/helpers/dateHelpers';
import {
  FLAG_INSIDE, EMAIL_SEND_ERR_OUT, REQUEST_STATUSES, REQUEST_IS_MULTIPASS,
} from '@/constants';

export const tableHeadersLocal = [
  {
    text: 'ID',
    value: 'ID',
    filterable: false,
    width: '80px',
  },
  {
    text: 'Name of visitor',
    value: 'DATA.V_NAME',
    cellClass: 'font-weight-medium',
    width: '240px',
  },
  {
    text: 'Status',
    value: 'STATE',
    filterable: false,
    width: '150px',
  },
  {
    text: 'Phone number',
    value: 'DATA.V_PHONE',
    sortable: false,
    width: '200px',
  },
  {
    text: 'Email',
    value: 'DATA.V_EMAIL',
    sortable: false,
    width: '200px',
  },
  {
    text: 'Name of visited objects',
    value: 'OBJECT_NAME',
    filterable: false,
    sortable: false,
    width: '200px',
  },
  {
    text: 'Multipass',
    value: 'MULTI_PASS',
    filterable: false,
    sortable: false,
  },
  {
    text: 'Start of the pass',
    value: 'DATE_BEGIN',
    filterable: false,
    width: '180px',
  },
  {
    text: 'End of the pass',
    value: 'DATE_END',
    filterable: false,
    width: '180px',
  },
  {
    text: 'Pass inside status',
    value: 'INSIDE',
    filterable: false,
    sortable: false,
    width: '250px',
  },
  {
    text: 'Date and time of the first pass',
    value: 'DATE_FIRST_PASS',
    filterable: false,
    width: '200px',
  },
  {
    text: 'Date and time of the first exit',
    value: 'DATE_LAST_EXIT',
    filterable: false,
    width: '200px',
  },
  {
    text: 'Requests code',
    value: 'CODE',
    sortable: false,
    width: '200px',
  },
  {
    text: 'Email sent status',
    value: 'SEND_ERR',
    filterable: false,
    sortable: false,
    width: '220px',
  },
  {
    text: 'User who created the request',
    value: 'CREATE_USER_NAME',
    filterable: false,
    sortable: false,
    width: '200px',
  },
  {
    text: 'User who modified the request',
    value: 'MOD_USER_NAME',
    filterable: false,
    sortable: false,
    width: '200px',
  },
  {
    text: 'Comments',
    value: 'DATA.NOTICE',
    sortable: false,
    width: '250px',
  },
  {
    text: 'Actions',
    value: 'ACTIONS',
    align: 'end',
    divider: true,
    class: 'fixed-right-cel',
    cellClass: 'fixed-right-cel',
    filterable: false,
    sortable: false,
  },
];

export const tableHeadersExternal = [...tableHeadersLocal];
tableHeadersExternal.splice(
  5,
  0,
  {
    text: 'External system',
    value: 'SYSTEM_NAME',
    sortable: false,
    width: '200px',
  },
  {
    text: 'SID',
    value: 'SID',
    sortable: false,
    width: '200px',
  },
);

export const filterModel = [
  {
    name: 'STATE',
    type: 'select',
    data: null,
    items: REQUEST_STATUSES,
    label: 'Status',
  },
  {
    name: 'SYSTEM_ID',
    type: 'multi-autocomplete',
    data: [],
    items: [],
    label: 'External system',
  },
  {
    name: 'SID',
    type: 'text-field',
    data: null,
    label: 'SID of request',
  },
  {
    name: 'OBJECT_ID',
    type: 'autocomplete',
    data: [],
    items: [],
    label: 'Name of visited objects',
  },
  {
    name: 'MULTIPASS',
    type: 'select',
    data: null,
    items: REQUEST_IS_MULTIPASS,
    label: 'Multipass',
  },
  {
    name: 'CODE',
    type: 'text-field',
    data: null,
    label: 'Requests code',
  },
  {
    name: 'SEND_ERR',
    type: 'select',
    data: null,
    items: EMAIL_SEND_ERR_OUT,
    label: 'Email sent status',
  },
  {
    name: 'INSIDE',
    type: 'select',
    data: null,
    items: FLAG_INSIDE,
    label: 'Pass inside status',
  },
  {
    name: 'CREATE_USER_ID',
    type: 'select',
    data: null,
    items: [],
    label: 'User who created the request',
  },
  {
    name: 'MOD_USER_ID',
    type: 'select',
    data: null,
    items: [],
    label: 'User who modified the request',
  },
  {
    type: 'space',
  },
  {
    type: 'space',
  },
  {
    name: 'START_S',
    type: 'date-time-picker',
    data: dateHelpers.getMonthAgo().startOf('day').toDate(),
    label: 'Start of the pass from',
  },
  {
    name: 'START_PO',
    type: 'date-time-picker',
    data: dateHelpers.getToday().endOf('day').toDate(),
    label: 'Start of the pass till',
  },
  {
    name: 'END_S',
    type: 'date-time-picker',
    data: null,
    label: 'End of the pass from',
  },
  {
    name: 'END_PO',
    type: 'date-time-picker',
    data: null,
    label: 'End of the pass till',
  },
  {
    name: 'FIRST_PASS_S',
    type: 'date-time-picker',
    data: null,
    label: 'Date and time of the first pass from',
  },
  {
    name: 'FIRST_PASS_PO',
    type: 'date-time-picker',
    data: null,
    label: 'Date and time of the first pass till',
  },
  {
    name: 'LAST_EXIT_S',
    type: 'date-time-picker',
    data: null,
    label: 'Data and last exit time from',
  },
  {
    name: 'LAST_EXIT_PO',
    type: 'date-time-picker',
    data: null,
    label: 'Data and last exit time till',
  },
];
