export default {
  'Order a one-time pass': 'Заказать разовый пропуск',
  'Nothing was found according to your request.': 'По вашему запросу ничего не найдено.',
  'There are no objects.': 'Объектов нет.',
  Select: 'Выбрать',
  Page: 'Страница',
  of: 'из',
  Login: 'Войти',
  'Unknown error!': 'Неизвестная ошибка!',
  'Search by name': 'Поиск по названию',
  'Send request': 'Отправить заявку',
  "By clicking on the 'Continue' button, I consent to the":
    'Нажимая на кнопку «Продолжить», я соглашаюсь на',
  'processing of personal data': 'обработку персональных данных',
  'and accept': 'и принимаю',
  'the Privacy Policy': 'Политику конфиденциальности',
  Continue: 'Продолжить',
  'New request': 'Новая заявка',
  'Request №': 'Заявка №',
  'Visit data': 'Данные для посещения',
  'Date of visit': 'Дата посещения',
  'Visit time': 'Время посещения',
  Register: 'Зарегистрировать',
  Save: 'Сохранить',
  'Required field': 'Обязательное поле',
  'Wrong time to visit': 'Некорректное время для посещения',
  'Incorrect visit period': 'Некорректный период посещения',
  'You have entered a past date': 'Вы ввели прошедшую дату',
  'Validation error. Check the correctness of the entered data.':
    'Ошибка валидации. Проверьте правильность введенных данных.',
  Review: 'Рассмотрение',
  Reviewed: 'Рассмотрена',
  Agreed: 'Согласована',
  Confirmed: 'Подтверждена',
  Rejected: 'Отклонена',
  'Once the request is approved, the status of the request will change to Approved':
    'Как только заявка будет одобрена, статус заявки изменится на «Подтверждена»',
  'Reusable pass': 'Многоразовый пропуск',
  'One time pass': 'Одноразовый пропуск',
  'Request with code': 'Заявка с кодом',
  'not found': 'не найдена',
  From: 'С',
  Till: 'До',
  Requests: 'Заявки',
  Apply: 'Применить',
  Clear: 'Очистить',
  Local: 'Локальные',
  External: 'Внешние',
  Administration: 'Администрирование',
  'External system': 'Внешняя система',
  'External systems': 'Внешние системы',
  Users: 'Пользователи',
  Equipment: 'Оборудование',
  'Access zones': 'Зоны доступа',
  'Objects to visit': 'Объекты посещения',
  Add: 'Добавить',
  'Search by requests': 'Поиск по заявкам',
  Filters: 'Фильтры',
  Collapse: 'Свернуть',
  'Adding a local request': 'Добавление локальной заявки',
  'Editing a local request': 'Редактирование локальной заявки',
  Status: 'Статус',
  'Name of visited objects': 'Наименование объекта посещения',
  'Requests code': 'Код заявки',
  'Email sent status': 'Статус отправки email',
  'Pass inside status': 'Нахождение пропуска внутри',
  'User who created the request': 'Пользователь, создавший заявку',
  'User who modified the request': 'Пользователь, изменивший заявку',
  'Start of the pass from': 'Начало действия пропуска с',
  'Start of the pass till': 'Начало действия пропуска по',
  'End of the pass from': 'Окончание действия пропуска с',
  'End of the pass till': 'Окончание действия пропуска по',
  'Date and time of the first pass from': 'Дата и время первого прохода c',
  'Date and time of the first pass till': 'Дата и время первого прохода по',
  'Data and last exit time from': 'Дата и время последнего выхода c',
  'Data and last exit time till': 'Дата и время последнего выхода по',
  'Name of visitor': 'Наименование посетителя',
  'Phone number': 'Номер телефона',
  'Start of the pass': 'Начало действия пропуска',
  'End of the pass': 'Окончание действия пропуска',
  'Date and time of the first pass': 'Дата и время первого прохода',
  'Date and time of the first exit': 'Дата и время последнего выхода',
  Comments: 'Комментарий',
  Actions: 'Действия',
  Passages: 'Проходы',
  Settings: 'Настройки',
  'Delete local application': 'Удалить локальную заявку',
  'Once deleted, local ticket data cannot be restored.':
    'После удаления восстановить данные локальной заявки будет невозможно.',
  Cancel: 'Отмена',
  Delete: 'Удалить',
  'You must select «Object of visit».': 'Необходимо выбрать «Объект посещения».',
  'You must select the value «Multipass»': 'Необходимо выбрать значение «Multipass».',
  'You must enter «Start date»': 'Необходимо заполнить «Дату начала».',
  'You must enter «End date»': 'Необходимо заполнить «Дату окончания».',
  'You must enter «Status»': 'Необходимо заполнить «Статус».',
  'You must enter «Phone number»': 'Необходимо заполнить «Номер телефона».',
  'You must enter «Name of visitor»': 'Необходимо заполнить «Наименование посетителя».',
  'You must enter «Email»': 'Необходимо заполнить «Email».',
  'You must enter «Password»': 'Необходимо заполнить «Пароль».',
  'Edit local requests': 'Редактирование локальной заявки ',
  'Change password': 'Сменить пароль',
  'Sign out': 'Выйти',
  'Old password': 'Старый пароль',
  'New password': 'Новый пароль',
  'Repeat new password': 'Повторить новый пароль',
  'Do you want to log out?': 'Вы хотите выйти из системы?',
  'Confirm deletion?': 'Подтвердить удаление?',
  'Login to your personal account': 'Вход в личный кабинет',
  Username: 'Логин',
  Password: 'Пароль',
  Yes: 'Да',
  No: 'Нет',
  'Sending was not': 'Отправка не выполнялась',
  'Successful sending': 'Успешная отправка',
  Error: 'Ошибка',
  'Pass inside the building, entered through the device of the outer perimeter':
    'Пропуск внутри здания, вошел через устройство внешнего периметра',
  'Pass inside the building, did not enter through the device of the outer perimeter':
    'Пропуск внутри здания, не входил через устройство внешнего периметра',
  'The pass left the building through the outer perimeter device':
    'Пропуск покинул здание через устройство внешнего периметра',
  'The pass left the building through the outer perimeter device after the expiration date':
    'Пропуск покинул здание через устройство внешнего периметра после окончания срока действия',
  To: 'До',
  'Update status': 'Обновить статус',
  'Show route': 'Показать маршрут',
  'Requests info': 'Информация о запросах',
  'Visited object ID': 'Идентификатор объекта посещения',
  Turnstile: 'Турникет',
  'Full name of the visitor': 'ФИО посетителя',
  Entry: 'Вход',
  Exit: 'Выход',
  'Full name, request ID, request SID': 'ФИО, ID заявки, SID заявки',
  'Object of visit': 'Объект посещения',
  'Device ID': 'Идентификатор устройства',
  'Device type': 'Тип устройства',
  'Device name': 'Наименование устройства',
  'Direction of passage': 'Направление прохода',
  'Date and time the records were created from': 'Дата и время создания записи с',
  'Date and time the records were created till': 'Дата и время создания записи по',
  'Date and time the records were created': 'Дата и время создания записи',
  'Passage status': 'Флаг совершенного прохода',
  'Request ID': 'ID заявки',
  'Request SID': 'SID заявки',
  'SID of request': 'SID заявки',
  'System name': 'Наименование системы',
  'Error message': 'Сообщение об ошибке',
  'Records per page': 'Строк на странице',
  'Electronic lock': 'Электронный замок',
  Email: 'Электронная почта',
  Hours: 'Часы',
  Minutes: 'Минуты',
  'Limit from': 'Лимит с',
  'Limit to': 'Лимит по',
  'Invalid username or password': 'Неверные логин или пароль',
  Close: 'Закрыть',
  Name: 'Наименование',
  Description: 'Описание',
  'Name, description': 'Наименование, описание',
  'Date and time of change': 'Дата и время изменения',
  'Date and time changed from': 'Дата и время изменения с',
  'Date and time changed to': 'Дата и время изменения по',
  'The user who made the change': 'Пользователь, внесший изменение',
  'Lines per page': 'Строк на странице',
  'Data not found': 'Данные не найдены',
  'Adding an Access Zone': 'Добавление зоны доступа',
  'Access Zone Editing': 'Редактирование зоны доступа',
  Role: 'Роль',
  'Name, login, description': 'Наименование, логин, описание',
  Generate: 'Сгенерировать',
  'IP address': 'IP адрес',
  'Outer perimeter': 'Внешний периметр',
  Configuration: 'Конфигурация',
  Options: 'Параметры',
  'Initialization string': 'Строка инициализации',
  'Let exit who did not enter from the outer perimeter':
    'Выпускать не вошедших по внешнему периметру',
  'Let exit expired passes from the outer perimeter':
    'Выпускать просроченные пропуска по внешнему периметру',
  'Let exit expired passes from the inner perimeter':
    'Выпускать просроченные пропуска по внутреннему периметру',
  'Name, address, description': 'Наименование, адрес, описание',
  'Name, key, description': 'Наименование, ключ, описание',
  Key: 'Ключ',
  User: 'Пользователь',
  'Delete access zone': 'Удалить зону доступа',
  'Delete equipment': 'Удалить оборудование',
  'Delete user': 'Удалить пользователя',
  'After deletion, it will be impossible to restore access zone data':
    'После удаления восстановить данные зоны доступа будет невозможно',
  'You must fill in «Name»': 'Необходимо заполнить «Название»',
  'You need to fill in «Status»': 'Необходимо заполнить «Статус»',
  'You need to fill in «Login»': 'Необходимо заполнить «Логин»',
  'You need to fill in «Password»': 'Необходимо заполнить «Пароль»',
  'You need to fill in «Role»': 'Необходимо заполнить «Роль»',
  'You need to fill «Type»': 'Необходимо заполнить «Тип»',
  'You need to fill in «SID»': 'Необходимо заполнить «SID»',
  'You need to fill in «Name»': 'Необходимо заполнить «Имя»',
  'You must select a value': 'Необходимо выбрать значение',
  'You need to fill in «IP address»': 'Необходимо заполнить «IP адрес»',
  'You need to fill in «Configuration»': 'Необходимо заполнить «Конфигурация»',
  'You need to fill in the «Key»': 'Необходимо заполнить «Ключ»',
  'You need to fill «User»': 'Необходимо заполнить «Пользователь»',
  copied: 'скопирован',
  'Key copied': 'Ключ скопирован',
  Active: 'Активна',
  Blocked: 'Заблокирована',
  Inside: 'Внутрь',
  Out: 'Наружу',
  'Not set': 'Не задан',
  Administrator: 'Администратор',
  'Pass Bureau': 'Бюро пропусков',
  'Organization (consideration)': 'Организация (рассмотрение)',
  'Organization (agreement)': 'Организация (согласование)',
  'Creation of requests': 'Создание заявок',
  Loading: 'Загрузка',
  Systemic: 'Системное',
  Host: 'Принимающая сторона',
  'Common data': 'Общие данные',
  'Add field': 'Добавить поле',
  'Field name': 'Наименование поля',
  'from 08:00 to 20:00': 'с 08:00 до 20:00',
  'File is loading': 'Идет загрузка файла',
  'File uploaded': 'Файл загружен',
  'Upload file': 'Загрузить файл',
  'This field must contain only numbers': 'Это поле должно содержать только цифры',
  Download: 'Скачать',
  Replace: 'Заменить',
  Logo: 'Логотип',
  'Logo for Wallet': 'Логотип для Wallet',
  'Agreement for the processing of personal data': 'Соглашение обработки персональных данных',
  'Privacy Policy': 'Политика конфиденциальности',
  'Error getting fields': 'Ошибка получения полей',
  'Adding local object to visit': 'Добавление локального объекта посещения',
  'Editing local object to visit': 'Редактирование локального объекта посещения',
  Header: 'Колонтитул',
  'Click to upload the .png file': 'Нажмите для загрузки файла в формате .png',
  'Recommended file resolution 150x150': 'Рекомендуемое разрешение файла 150x150',
  'Delete object to visit': 'Удалить объект посещения',
  'Delete external system': 'Удалить внешнюю систему',
  'After deletion, it will be impossible to restore the data of the visited object':
    'После удаления восстановить данные объекта посещения будет невозможно',
  'Loading error': 'Ошибка загрузки',
  'Adding equipment': 'Добавление оборудования',
  'Equipment editing': 'Редактирование оборудования',
  'Adding a user': 'Добавление пользователя',
  'Editing a user': 'Редактирование пользователя',
  'Adding an External system': 'Добавление внешней системы',
  'Editing an External system': 'Редактирование внешней системы',
  'Upload from file': 'Загрузка из файла',
  'You can upload .csv file format only': 'Загрузить можно только файл формата .csv',
  'Incorrect file or data format': 'Неверный файл или формат данных',
  consentPersonalDataPart1: 'Я\xa0принимаю',
  consentPersonalDataPart2: 'согласие на\xa0обработку персональных данных',
  consentPersonalDataValidation: 'Для подачи заявки необходимо поставить галочку в\xa0поле "Я\xa0принимаю согласие на\xa0обработку персональных данных"',
  initialApplicationStatus: 'Начальный статус заявки',
  singlePass: 'Одноразовые',
  multiPass: 'Многоразовые',
  singleAndMultiPass: 'Одноразовые и многоразовые',
  possibleOmissionOptions: 'Возможные варианты пропусков',
  defaultPassType: 'Тип пропуска по умолчанию',
  systemValue: 'Системное значение',
};
