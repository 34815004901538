<template>
  <div>
    <v-tabs class="srp-tabs">
      <v-col cols="auto" align-self="center">
        <h2 class="text-h2 text--primary mr-6">{{ $t("Settings") }}</h2>
      </v-col>
      <v-tab>{{ $t("Requests") }}</v-tab>
      <v-tab>{{ $t("Systemic") }}</v-tab>
      <v-tab-item class="pl-16 pt-8"><Requests /></v-tab-item>
      <v-tab-item class="pl-16 pt-8"><System /></v-tab-item>
    </v-tabs>
  </div>
</template>

<script>
import Requests from './Requests.vue';
import System from './System.vue';

export default {
  name: 'SettingsLayout',
  components: { Requests, System },
};
</script>
