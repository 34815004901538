<template>
  <v-data-table
    class="table-striped"
    loading-text="Загрузка..."
    sort-by="ID"
    :footer-props="{
      'items-per-page-text': $t('Records per page') + ':',
      'items-per-page-options': [15, 25, 50, 100],
    }"
    :headers="translatedTableHeaders"
    :header-props="{ 'sort-icon': '$swap' }"
    :items="objects"
    :items-per-page="-1"
    :loading="tableLoading"
    :sort-desc="true"
    :no-data-text="$t('Data not found')"
    :no-results-text="$t('Data not found')"
    :search="search"
  >
    <template #[`item.ACTIONS`]="{ item }">
      <div class="d-inline-flex">
        <v-tooltip bottom color="primary">
          <template #activator="{ on, attrs }">
            <router-link :to="`/passages?request_id=${item.ID}`">
              <v-icon v-bind="attrs" class="ml-4" color="primary" v-on="on">
                $document
              </v-icon>
            </router-link>
          </template>
          {{ $t("Passages") }}
        </v-tooltip>
        <template v-if="item.SYSTEM_ID === 0">
          <v-icon class="ml-4" color="red" @click="$emit('delete', item)">
            $delete
          </v-icon>
          <v-icon class="ml-4" color="primary" @click="$emit('edit', item)">
            $edit
          </v-icon>
        </template>
      </div>
    </template>
    <template #[`item.STATE`]="{ item }">
      <span :class="REQUEST_STATUSES.find((elem) => elem.value == item.STATE)?.colorClassName">
        {{ $t(REQUEST_STATUSES.find((elem) => elem.value == item.STATE)?.text) }}
      </span>
    </template>
    <template #[`item.MULTI_PASS`]="{ item }">
      {{ $t(REQUEST_IS_MULTIPASS.find((elem) => elem.value == item.MULTI_PASS)?.text) }}
    </template>
    <template #[`item.LOGO`]="{ item }">
      <img
        :src="
          item.LOGO ? convertUserLogo(item.LOGO) : require('../../assets/images/default-avatar.png')
        "
        style="width: 25px; height: 25px"
        alt="logo"
      >
    </template>
    <template #[`item.LOGO_WALLET`]="{ item }">
      <img
        :src="
          item.LOGO
            ? convertUserLogo(item.LOGO_WALLET)
            : require('../../assets/images/default-avatar.png')
        "
        style="width: 25px; height: 25px"
        alt="logo"
      >
    </template>
    <template #[`item.DATE_BEGIN`]="{ item }">
      {{ dateHelpers.getDmyHmFormattedDate(item.DATE_BEGIN) }}
    </template>
    <template #[`item.DATE_END`]="{ item }">
      {{ dateHelpers.getDmyHmFormattedDate(item.DATE_END) }}
    </template>
    <template #[`item.USER_ID`]="{ item }">
      {{ allUsers.find((elem) => elem.value == item.USER_ID)?.text }}
    </template>
    <template #[`item.INSIDE`]="{ item }">
      {{ FLAG_INSIDE.find((elem) => elem.value == item.INSIDE)?.text }}
    </template>
    <template #[`item.SEND_ERR`]="{ item }">
      <div class="d-flex justify-space-between">
        {{ $t(EMAIL_SEND_ERR_IN.find((elem) => elem.value === item.SEND_ERR)?.text) }}
        <v-tooltip v-if="item.SEND_ERR === false" bottom color="primary">
          <template #activator="{ on, attrs }">
            <v-icon v-bind="attrs" color="error" v-on="on">
              $attention
            </v-icon>
          </template>
          {{ item.SEND_MSG || "Неизвестная ошибка" }}
        </v-tooltip>
      </div>
    </template>
  </v-data-table>
</template>

<script>
import api from '@/api';
import {
  REQUEST_STATUSES,
  REQUEST_IS_MULTIPASS,
  FLAG_INSIDE,
  EMAIL_SEND_ERR_IN,
} from '@/constants';
import dateHelpers from '@/helpers/dateHelpers';

export default {
  name: 'LocalObjectsTable',
  props: {
    objects: {
      type: Array,
      default() {
        return [];
      },
    },
    tableHeaders: {
      type: Array,
      required: true,
    },
    search: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      REQUEST_STATUSES,
      REQUEST_IS_MULTIPASS,
      FLAG_INSIDE,
      dateHelpers,
      EMAIL_SEND_ERR_IN,
      tableLoading: false,
      allUsers: [],
    };
  },
  computed: {
    translatedTableHeaders() {
      return this.tableHeaders.map((elem) => ({ ...elem, text: this.$t(elem.text) }));
    },
  },
  mounted() {
    api.users.getShortList().then((res) => res.data.DATA.forEach((user) => this.allUsers.push({
      text: user.NAME,
      value: user.ID,
    })));
  },
  methods: {
    convertUserLogo(logo) {
      if (!logo) return '';
      if (logo.includes('data:image/png;base64,')) return logo;
      return `data:image/png;base64, ${logo}`;
    },
  },
};
</script>
