<template>
  <v-input v-model="timeRange" v-bind="{ ...textFieldProps }">
    <div class="d-flex align-center">
      <div cols="1" class="pr-2 text-center text--primary text-no-wrap">
        {{ $t("From") }}
      </div>
      <vue-timepicker
        v-model="timeStart"
        input-width="100%"
        placeholder="00:00"
        :hour-label="$t('Hours')"
        :minute-label="$t('Minutes')"
        close-on-complete
      />
    </div>
    <div class="d-flex align-center">
      <div cols="2" class="px-2 text-center text--primary">
        {{ $t("To") }}
      </div>
      <vue-timepicker
        v-model="timeEnd"
        input-width="100%"
        placeholder="00:00"
        :hour-label="$t('Hours')"
        :minute-label="$t('Minutes')"
        close-on-complete
      />
    </div>
  </v-input>
</template>

<script>
import VueTimepicker from 'vue2-timepicker/src/vue-timepicker.vue';

export default {
  name: 'TimeRangePicker',
  components: { VueTimepicker },
  props: {
    textFieldProps: {
      type: Object,
      default: () => {},
    },
    value: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      timeStart: '',
      timeEnd: '',
    };
  },
  computed: {
    timeRange: {
      get() {
        return this.value;
      },
      set(value) {
        return this.$emit('input', value);
      },
    },
  },
  watch: {
    timeStart() {
      this.saveTimeRange();
    },
    timeEnd() {
      this.saveTimeRange();
    },
    timeRange() {
      if (this.timeRange) {
        // синхронизация v-model если обновился снаружи
        const period = this.timeRange.match(/(\d{2}:\d{2})/g);
        if (!period) return false;
        if (!(period.length >= 2)) return false;
        this.timeStart = period[0];
        this.timeEnd = period[1];
      }
    },
  },
  mounted() {
    this.timeStart = '05:00';
    this.timeEnd = '20:00';
  },
  methods: {
    saveTimeRange() {
      if (!this.timeStart && !this.timeEnd) return (this.timeRange = '');
      this.timeRange = `с ${this.timeStart} до ${this.timeEnd}`;
    },
  },
};
</script>

<style lang="scss">
.v-input {
  &.error--text {
    .vue__time-picker input.display-time {
      border-color: $srp-red-color;
    }
  }

  .vue__time-picker {
    font-family: inherit;
  }

  .vue__time-picker input.display-time {
    border-color: $srp-text-secondary;
    border-radius: 4px;
    max-height: 100%;
    height: 50px;
  }

  .vue__time-picker .controls > * {
    padding: 0 0.75em;
  }

  .vue__time-picker .dropdown ul li:not([disabled]).active {
    background: $srp-primary;
  }

  .vue__time-picker-dropdown ul li:not([disabled]).active {
    background: $srp-primary;
  }

  @media #{map-get($display-breakpoints, 'sm-and-down')} {
    .vue__time-picker .dropdown .hint,
    .vue__time-picker-dropdown .hint {
      font-size: 11px;
    }
  }
}
</style>
