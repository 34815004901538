<script>
import store from '@/store/index.js';
import { REQUEST_STATUSES } from '@/constants/index.js';

export default {
  name: 'RequestStatuses',
  props: {
    defaultPassStatus: {
      type: Number,
      default: store.getters.configDefaultPassStatus.VALUE,
    },
  },
  data() {
    return {
      value: this.defaultPassStatus,
      requestStatusesRules: [(v) => !!v || v === 0 || this.$t('You must enter «Status»')],
    };
  },
  watch: {
    defaultPassStatus: {
      handler() {
        this.value = this.defaultPassStatus;
      },
    },
  },
  methods: {
    requestStatuses(excludedStatuses = []) {
      const getResult = (statuses) => statuses
        .map((status) => ({ ...status, text: this.$t(status.text) }));

      return excludedStatuses
        ? getResult(REQUEST_STATUSES.filter((status) => !excludedStatuses.includes(status.text)))
        : getResult(REQUEST_STATUSES);
    },
  },
};
</script>

<template>
  <v-select
    v-model="value"
    :items="requestStatuses(['Rejected'])"
    :menu-props="{ offsetY: true }"
    class="mb-5"
    append-icon="$down"
    hide-details="auto"
    outlined
    :label="$t('initialApplicationStatus')"
    @change="$emit('update-state-fabric', value)"
  />
</template>
