export default function admin({ next, store }) {
  if (store.state.user.model.ROLE !== 0) {
    return next('/login');
  }

  if (!store.state.systemConfig.isConfigLoaded) {
    store.dispatch('fetchSystemConfig');
  }

  return next();
}
