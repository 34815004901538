<template>
  <v-btn
    class="ml-2"
    outlined
    color="primary"
    small
    rounded
    text
    @click="changeLang($i18n.locale === 'ru' ? 'en' : 'ru')"
  >
    {{ $i18n.locale === "ru" ? "EN" : "RU" }}
  </v-btn>
</template>

<script>
export default {
  name: 'LangBtn',

  methods: {
    changeLang(newLang) {
      this.$cookies.set('lang', newLang);
      this.$i18n.locale = newLang;
    },
  },
};
</script>
